import React from 'react'
import {
  Box,
  Typography,
} from "@material-ui/core";
import { imgShevronRight } from './assets';
import { getTranslationConfig } from './helpers';

export function BreadCrumbs({
  items,
  targetColor,
  fontSize,
  color,
  navigationMap = {},
  navigate = () => { }
}: {
  items: string[],
  targetColor?: string,
  fontSize?: string,
  color?: string,
  navigationMap?: { [key: string]: string },
  navigate?: (route: string) => void
}) {
  const token = localStorage.getItem('authToken');
  
  if (!token) {
    return null;
  }

  // Remove items and map entity for first items (Home)
  const removeFirstItem = () => {
    const navigationKeys = Object.keys(navigationMap);
    items.shift();
    items.length < navigationKeys.length + 1 && delete navigationMap[navigationKeys[0]];
  }

  const isSPA = localStorage.getItem('userRole') === 'Spa';
  isSPA && removeFirstItem();

  const itemsColor = color || '#94A3B8';

  const { isArabic } = getTranslationConfig();

  const handleBreadcrumbClick = (index: number) =>
    index < items.length - 1 && navigate(Object.values(navigationMap)[index]);

  return (
    <Box display='flex'>
      {items.map((item, index) => {
        const isLastItem = index === items.length - 1;

        return (
          <Box key={`${item}_${index}`} display='flex'>
            <Typography
              data-testid={`breadcrumb-${item}`}
              style={{
                fontFamily: 'Ubuntu',
                fontSize: fontSize || '16px',
                lineHeight: '14px',
                cursor: 'pointer',
                color: isLastItem ? (targetColor || itemsColor) : itemsColor,
                ...(isLastItem && { fontWeight: 700 })
              }}
              onClick={() => handleBreadcrumbClick(index)}
            >
              {item}
            </Typography>
            {!isLastItem &&
              <img
                src={imgShevronRight}
                alt='shevron'
                style={{
                  padding: '0 8px',
                  ...(isArabic && { transform: 'rotate(180deg)' })
                }}
              />}
          </Box>)
      })}

    </Box>)
}
