export const sourceKey = 'QUl6YVN5Q0RzOTU0TXdFcXBBTzZ2dkJCYktGTEhfNXJ4eFRTWlZR';
export const publicStripeKey =
  'pk_test_51Q7gFdIXoDkwrNVLPTZccyFM4kQueUCTjFb96QEmuTXcRuVz6QMB4q8MQohufC8xx9yiODXQNPnKLAVLR6k5DH7c00AooVocUP';

export enum LanguageNames {
  EN = 'English',
  AR = 'Arabic',
}

export const arDays: Record<string, string> = {
  اح: 'Sun',
  سب: 'Sat',
  جم: 'Fri',
  خم: 'Thu',
  ار: 'Wed',
  ثل: 'Tue',
  ثن: 'Mon',
  Sun: 'Sun',
  Sat: 'Sat',
  Fri: 'Fri',
  Thu: 'Thu',
  Wed: 'Wed',
  Tue: 'Tue',
  Mon: 'Mon',
};

export const arabicMonths: Record<string, string> = {
  jan: 'كانون الثاني',
  feb: 'شباط',
  mar: 'آذار',
  apr: 'نيسان	',
  may: 'أيار',
  jun: 'حزيران',
  jul: 'تموز',
  aug: 'آب',
  sep: 'أيلول',
  oct: 'تشرين الأول',
  nov: 'تشرين الثاني',
  dec: 'كانون الأول'
};

export const restrictedSubscriptionId = 1;
export const servicesRestriction = 3;

export const planExpiry = 'plan-expiry';
