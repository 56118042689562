import React from "react";
// Customizable Area Start
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import CheckIcon from "@material-ui/icons/Check";
import CurrencyList from "currency-list";
import { spaSettingsCover, successMessageIcon } from "./assets";
import UserProfileBasicController, {
  Props,
} from "./UserProfileBasicController";
import { BookingWrapper } from "../../ordermanagement1/src/components/BookingWrapper";
import ConfirmationModal from "../../../components/src/ConfirmationModal";
import UpdateDetailsModal from "./components/UpdateDetailsModal";
import { UpdateButton } from "./CutomerSettings.web";
import { StyledBox } from './styles';
import { getIsTrial, getTranslationConfig } from '../../../components/src/helpers';
import { AccountButtons } from './components/AccountButtons';
import { PlanExpiryPrompt } from '../../../components/src/PlanExpiryPrompt';

export enum UpdateType {
  email = 'email',
  emailConfirm = 'emailConfirm',
  phone = 'phone',
  password = 'password',
  passwordConfirm = 'passwordConfirm'
}
// Customizable Area End

export default class SpaOwnerSettings extends UserProfileBasicController {
  loadCurrencyRef: any;

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.loadCurrencyRef = React.createRef();
    // Customizable Area End
  }
  // Customizable Area Start
  navigationMap = {
    'Home': 'LandingPage',
    'Spa Profile': 'SpaUserProfile',
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { t, dir } = getTranslationConfig();
    const customStyles = {
      background: `linear-gradient(
                   rgba(0, 0, 0, 0.2), 
                   rgba(0, 0, 0, 0.5)
                   ), url(${spaSettingsCover}) center bottom 30% / 100% auto no-repeat`,
    };

    const { openConfirmationModal } = this.state;

    if (!this.loadCurrencyRef.current) {
      const { isArabic } = getTranslationConfig();
      const locale = isArabic ? 'ar_EG' : 'en_US';

      const currencyOptions = Object.values(CurrencyList.getAll(locale)).map(
        (curr: Record<string, string>) => ({
          name: curr.name,
          code: curr.code,
        })
      );
      this.setCurrencyList(currencyOptions);

      this.loadCurrencyRef.current = true;
    }

    return (
      <Box
        bgcolor="#D9EEEB"
      >
        <BookingWrapper
          navigation={this.props.navigation}
          navigationMap={this.navigationMap}
          backgroundImage={spaSettingsCover}
          heading={t['profile-settings-heading']}
          breadcrumbs={[t['breadcrumbs-home'], t['breadcrumbs-spa-profile'], t['breadcrumbs-settings']]}
          customStyles={customStyles}
          forceUpdate={() => {
            this.loadCurrencyRef.current = false;
            this.forceUpdate();
          }}
          context={this}
        >
          <Box
            dir={dir}
            bgcolor="#FFF"
            marginTop={4}
            py={5}
            style={{
              borderRadius: '8px',
              boxShadow: '0px 8px 32px 0px #0000000F'
            }}>
            <Accordion
              data-test-id="collapse"
              style={{
                border: "none",
                boxShadow: "none",
                marginBottom: "2rem",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                style={{
                  fontFamily: "Ubuntu",
                  fontWeight: 700,
                  lineHeight: "32px",
                  fontSize: "24px",
                  letterSpacing: "-0.12px",
                  borderBottom: "1px solid #E2E8F0",
                  paddingBottom: '8px',
                  paddingLeft: '24px'
                }}
              >
                {t['profile-settings-currency']}
              </AccordionSummary>
              <AccordionDetails
                style={{ flexDirection: "column", padding: "2rem" }}
              >
                <TextField
                  placeholder={t['profile-settings-search-placeholder']}
                  data-test-id="search-currency"
                  style={{
                    border: "1px solid #CBD5E1",
                    borderRadius: "0.5rem",
                    padding: "6px 16px 6px",
                  }}
                  onChange={(e) => this.handleSearchChange(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon
                        style={{ fill: "#DADADA", margin: "0 0.5rem" }}
                      />
                    ),
                    disableUnderline: true,
                  }}
                />
                <FormControl
                  component="fieldset"
                  style={{
                    height: "17rem",
                    overflowY: "auto",
                    margin: "1.5rem 0",
                  }}
                >
                  <RadioGroup
                    name="currency"
                    defaultValue={
                      this.state.userDetails.attributes.currency || ""
                    }
                    onChange={(e) => this.handleUserAction() && this.handleSettingsChange(e.target.value)}
                  >
                    {this.state.displayCurrencyList.map((curr) => (
                      <FormControlLabel
                        value={curr.code}
                        data-test-id={`curr-${curr.code}`}
                        control={
                          <Radio
                            checkedIcon={
                              !getIsTrial() && <CheckIcon style={{ fill: "#398378" }} />
                            }
                            icon={<span />}
                          />
                        }
                        label={curr.name}
                        labelPlacement="start"
                        style={{
                          justifyContent: "space-between",
                          height: "3rem",
                          marginLeft: 0,
                          marginRight: 0,
                        }}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </AccordionDetails>
            </Accordion>
            <StyledBox>
              <Accordion
                data-test-id="collapse"
                style={{ border: "none", boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{
                    fontFamily: "Ubuntu",
                    fontWeight: 700,
                    lineHeight: "32px",
                    fontSize: "24px",
                    letterSpacing: "-0.12px",
                    borderBottom: "1px solid #E2E8F0",
                    borderTop: 'none',
                    paddingBottom: '8px',
                    paddingLeft: '24px'
                  }}
                >
                  {t['profile-settings-upd-profile-info']}
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: "column" }}>
                  <Box>
                    <InputLabel
                      style={{
                        fontFamily: "Ubuntu",
                        fontWeight: 700,
                        lineHeight: "24px",
                        fontSize: "16px",
                        color: "#334155",
                        marginBottom: "-9px",
                      }}
                    >
                      {t['profile-settings-email']}
                    </InputLabel>
                    <Box display="flex" alignItems="end" gridGap='72px'>
                      <TextField
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="email"
                        style={{
                          borderRadius: "8px",
                          border: "1px #CBD5E1",
                          width: "30rem",
                        }}
                        value={
                          this.state.email ||
                          this.state.userDetails.attributes.email ||
                          ""
                        }
                      />
                      <UpdateButton
                        data-test-id="email-input"
                        style={{ marginLeft: "1rem", fontSize: ' 14px', marginBottom: '8px' }}
                        onClick={() =>
                          this.handleOpenUpdateModal(UpdateType.email)
                        }
                      >
                        {t['profile-settings-change-btn']}
                      </UpdateButton>
                    </Box>
                  </Box>
                  <Box>
                    <InputLabel
                      style={{
                        fontFamily: "Ubuntu",
                        fontWeight: 700,
                        lineHeight: "24px",
                        fontSize: "16px",
                        color: "#334155",
                        marginBottom: "-9px",
                        marginTop: '16px'
                      }}
                    >
                      {t['profile-settings-phone']}
                    </InputLabel>
                    <Box display="flex" alignItems="end" gridGap='72px'>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        style={{
                          borderRadius: "8px",
                          border: "1px #CBD5E1",
                          width: "30rem",
                        }}
                        value={
                          this.state.phoneNumber ||
                          this.state.userDetails.attributes.phone_number ||
                          ""
                        }
                      />
                      <UpdateButton
                        data-test-id="phone-input"
                        style={{ marginLeft: "1rem", fontSize: ' 14px', marginBottom: '8px' }}
                        onClick={() => this.handleOpenUpdateModal(UpdateType.phone)}
                      >
                        {t['profile-settings-change-btn']}
                      </UpdateButton>
                    </Box>
                  </Box>
                  <Box>
                    <InputLabel
                      style={{
                        fontFamily: "Ubuntu",
                        fontWeight: 700,
                        lineHeight: "24px",
                        fontSize: "16px",
                        color: "#334155",
                        marginTop: "1rem",
                        marginBottom: "-9px",
                      }}
                    >
                      {t['profile-settings-password']}
                    </InputLabel>
                    <Box display="flex" alignItems="end" gridGap='72px'>
                      <TextField
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="password"
                        name="password"
                        value={"*******"}
                        style={{
                          borderRadius: "8px",
                          border: "1px #CBD5E1",
                          width: "30rem",
                        }}
                      />
                      <UpdateButton
                        style={{ marginLeft: "1rem", fontSize: ' 14px', marginBottom: '8px' }}
                        data-test-id="update-password-button"
                        onClick={() => this.handleOpenUpdateModal(UpdateType.password)}
                      >
                        {t['profile-settings-upd-password-btn']}
                      </UpdateButton>
                    </Box>
                  </Box>
                  <AccountButtons
                    t={t}
                    handleOpenModal={this.handleOpenModal}
                    handleLogout={this.handleLogout}
                    handleDeleteAccount={this.handleDeleteAccount}
                  />
                </AccordionDetails>
              </Accordion>
            </StyledBox>
          </Box>
        </BookingWrapper>
        <ConfirmationModal
          openConfirmationModal={openConfirmationModal}
          setOpenConfirmationModal={this.setOpenConfirmationModal}
          modalConfig={this.state.modalConfig}
        />
        <UpdateDetailsModal
          openUpdateModal={this.state.openUpdateModal}
          setOpenUpdateModal={this.handleCloseUpdateModal}
          updateType={this.state.updateType}
          navigation={undefined}
          id={""}
        />
        <Snackbar
          dir={dir}
          open={this.state.currencyUpdated}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3000}
          onClose={this.handleCloseSnackBar}
        >
          <Box
            display="flex"
            bgcolor="#FFF"
            borderRadius="8px"
            px="1rem"
            py="0.5rem"
            style={{ gap: "1rem" }}
          >
            <img src={successMessageIcon} />
            <Typography>{t['profile-settings-currency-saved']}</Typography>
          </Box>
        </Snackbar>
        <PlanExpiryPrompt context={this} userAction={this.state.userAction} />
      </Box>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
}
