export type ModalConfig = {
  title: string;
  message: string;
  confirmText: string;
  cancelText: string;
  onConfirm: () => void;
};

export const defaultModalConfig = {
  title: '',
  message: '',
  confirmText: '',
  cancelText: '',
  onConfirm: () => {},
};
