Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = "";
exports.labelBodyText = "dashboard Body";
exports.couponsApiUrl = "bx_block_coupon_cg/coupon_code_generator";
exports.userCataloguesApiUrl = "bx_block_catalogue/user_catalogues";
exports.userCataloguesGetMethod = "GET";
exports.couponsApiGetMethod = "GET";
exports.couponsApiPOSTMethod = "POST";
exports.couponsApiPATCHMethod = "PATCH";
exports.couponsApiDELETEMethod = "DELETE";
exports.couponNameError = 'Please enter coupon name';
exports.couponCodeError = 'Please enter coupon code';
exports.couponCodeSymbolsError = 'Coupon code may contain only letters and numbers';
exports.discountTypeError = 'Please select discount type';
exports.percentageTypeError = 'Please enter discount';
exports.validityError = 'Please choose validity dates';
exports.serviceError = 'Please select service name';
exports.usageTypeError = 'Please select usage type';
exports.usageLimitError = 'Please enter usage limit';
exports.commonErrorText = "There is an error";
exports.commonErrorDescription = "Cannot retrieve data from server";
// Customizable Area End