// Customizable Area Start
import React, { ChangeEvent, FunctionComponent } from "react";
import {
  Box,
  Button,
  MenuItem,
  Checkbox,
  InputLabel,
  TextField,
  Typography,
  Modal,
  CircularProgress,
  LinearProgress,
  Snackbar,
  Popover,
} from "@material-ui/core";
import {
  CarouselProvider,
  Slide,
  Slider,
  Image,
  Dot,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { Autocomplete } from "@material-ui/lab";
import DurationPicker from "react-duration-picker";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import {
  timeRangeArrow,
  editIcon,
  imgArrowDownTriangle,
  fileUploadImg,
  addIcon,
  imgCarouselArrowBack,
  imgCarouselArrowNext,
  imgDollarSign,
  imgLocationSearch,
  imgPendingClock,
  uploadImg,
  filesIcon,
  threeDotIcon,
  bussiness_location,
  addWhiteIcon,
  deleteWhiteIcon,
  AddTimingIcon,
  clockIcon,
  deleteLicence,
  editLicense,
  closeIcon,
  timerClock,
  calendarIcon,
  successMessageIcon,
} from "./assets";
import { AppFooter } from "../../../components/src/AppFooter.web";
import "pure-react-carousel/dist/react-carousel.es.css";
import UserProfileBasicController, {
  FileWithId,
  ImgObj,
  PopoverAnchor,
  Props,
  configJSON,
  spaTimingSlots,
} from "./UserProfileBasicController";
import {
  CustomTypography,
  CustomPopover,
} from "./SpaUserProfile.web";
import { arrowDown } from "../../email-account-registration/src/assets";
import {
  RegistrationFormView,
  TypographyView,
  styles,
} from "../../../components/src/styles";
import { BreadCrumbs } from "../../../components/src/BreadCrumbs.web";
import DatePicker from "react-multi-date-picker";
import "../../../components/src/durationPicker.css";
import "../../../components/src/calendar.css";
import { createUploaders } from "../../utilities/src/handle-files";
import {
  calculateSlots,
  generateDatesRange,
} from "../../../components/src/datesRangeHelper";
import { renderDatarange } from '../../../components/src/renderDataRange';
import MapLocation from '../../../components/src/MapLocation';
import MapLocationAdv from '../../../components/src/MapLocationAdv';
import { ModalWrapper, ModalWrapperComponent } from '../../../components/src/ModalWrapperComponent';
import { PlanExpiryPrompt } from '../../../components/src/PlanExpiryPrompt';

export const Spinner: FunctionComponent = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "42vh",
    }}
  >
    <CircularProgress style={{ color: "#398378" }} />
  </Box>
);

// Customizable Area End

class BasicInformation extends UserProfileBasicController {
  backBtnRef: any;
  nextBtnRef: any;
  firstLoad: any;
  calendarLinkRef: any | null = React.createRef();

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.backBtnRef = React.createRef();
    this.nextBtnRef = React.createRef();
    this.firstLoad = React.createRef();
    // Customizable Area End
  }
  // Customizable Area Start
  navigationMap = {
    'Home': 'LandingPage',
    'Spa Profile': 'SpaUserProfile',
  };

  uploadFileItem = ({
    stateSetter,
    titleText,
    uploadedFiles,
    popoverAnchor,
  }: {
    stateSetter: (file: File[]) => void;
    titleText: string;
    uploadedFiles: FileWithId[];
    popoverAnchor: PopoverAnchor;
  }) => (
    <>
      <Box sx={{ display: "flex", paddingTop: "15px", flexDirection: "row" }}>
        <InputLabel className="testLabel">{titleText}</InputLabel>
        {popoverAnchor !== "anchorElOtherFiles" && (
          <InputLabel className="testStar">{"*"}</InputLabel>
        )}
      </Box>
      {uploadedFiles.map((file, index) => {
        const { uploading, progressBars } = this.state;
        uploading &&
          !progressBars[index] &&
          !file.id &&
          this.setExpectedProgress(index);
        return (
          <Box key={index}>
            <Box
              mb={1}
              className="uploadedDocument"
              style={{
                boxShadow: "0px 8px 32px 0px #0000000F",
              }}
            >
              <Box className="removeItem">
                <img
                  src={filesIcon}
                  alt={""}
                  style={{ width: "20px", height: "20px", objectFit: "cover" }}
                />
              </Box>
              <CustomTypography
                style={{
                  width: "100%",
                  paddingLeft: "0.5rem",
                  overflow: "hidden",
                }}
              >
                <Typography className="licenseTxt" style={styles.dotsOverflow}>
                  {file.name}
                </Typography>
                <Typography className="fileSize">
                  (File type - .pdf, .jpg, .jpeg or .png)
                </Typography>
              </CustomTypography>
              <Box>
                <img
                  data-test-id="three-dot-menu"
                  style={{ cursor: "pointer" }}
                  src={threeDotIcon}
                  onClick={(event: React.MouseEvent<HTMLImageElement>) =>
                    this.setAnchor(index, popoverAnchor, event.currentTarget)
                  }
                />
                <CustomPopover
                  data-test-id="licenses-edit-popover"
                  open={Boolean(this.state[popoverAnchor][index])}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  anchorEl={this.state[popoverAnchor][index]}
                  onClose={this.closeFilesPopOver}
                  PaperProps={{
                    style: {
                      height: 82,
                      width: "192px",
                      boxShadow: "none",
                      borderRadius: "8px",
                      borderColor: "#CBD5E1",
                      marginTop: "24px",
                      marginLeft: "-8px",
                    },
                  }}
                >
                  <div
                    style={{
                      padding: 4,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      className="popoverButton"
                      style={{
                        padding: "9px 20px 9px 20px",
                        textTransform: "none",
                        fontWeight: 400,
                        height: "36px",
                      }}
                    >
                      <img src={editLicense} />
                      <Typography style={{ marginLeft: 8 }}>Edit</Typography>
                      <input
                        id="inputinuserblock"
                        data-test-id="upload-license"
                        accept=".pdf, .jpg, .jpeg ,.png"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const targetFiles = event.target.files;
                          if (targetFiles) {
                            this.addFilesForRemove(
                              uploadedFiles,
                              index,
                              popoverAnchor
                            );
                            uploadedFiles.splice(index, 1, targetFiles[0]);
                            stateSetter(uploadedFiles);
                          }
                        }}
                        className="imgContainer"
                        type="file"
                      />
                    </Button>
                    <Button
                      data-testId="delete-license-doc"
                      className="popoverButton"
                      style={{
                        padding: "9px 20px",
                        height: "36px",
                        textTransform: "none",
                        fontWeight: 400,
                      }}
                      onClick={() => {
                        this.addFilesForRemove(
                          uploadedFiles,
                          index,
                          popoverAnchor
                        );
                        uploadedFiles.splice(index, 1);
                        stateSetter(uploadedFiles);
                      }}
                    >
                      <img src={deleteLicence} />
                      <Typography
                        style={{
                          marginLeft: 8,
                          color: "#DC2626",
                          fontFamily: "Ubuntu",
                        }}
                      >
                        Delete
                      </Typography>
                    </Button>
                  </div>
                </CustomPopover>
              </Box>
            </Box>
            <CustomUploadingBar
              open={this.getCombinedLogicalValue(
                !!progressBars[index],
                !file.id
              )}
              progress={progressBars[index]}
            />
          </Box>
        );
      })}
      <Box
        mt="20px"
        display={"flex"}
        alignItems={"center"}
        position="relative"
        style={{
          gap: 8,
          cursor: "pointer",
        }}
      >
        <img src={addIcon} />
        <Typography
          style={{
            color: "#398378",
            fontFamily: "Ubuntu",
            fontSize: 16,
            fontWeight: 700,
          }}
        >
          Add File
        </Typography>
        <input
          data-testId="addNewFiles"
          accept=".pdf, .jpg, .jpeg ,.png"
          id="inputinuserblock"
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const targetFiles = event.target.files;
            targetFiles && uploadedFiles.push(...targetFiles);
            stateSetter(uploadedFiles);
          }}
          type="file"
        />
      </Box>
    </>
  );

  // Customizable Area End
  render() {
    // Customizable Area Start
    const popperStyle = {
      bgcolor: 'background.paper',
      border: '1px solid #CBD5E1',
      borderRadius: '8px',
      boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)',
      marginTop: '2px'
    };
    const {
      bio: aboutUs,
      address
    } =
      this.state.userDetails.attributes.profile_data.attributes;
    const {
      full_name: SPAVenueName,
      carousal_images: carouselImages,
      open_timing: openTiming,
      spa_timings: spaTimings,
    } = this.state.userDetails.attributes;

    const start_time = spaTimings[0].start_time;
    const end_time = spaTimings[0].end_time;
    const futureOpenTiming = `${start_time} - ${end_time}`;

    const services = this.state.services;

    const { durationFrom, durationTo } = this.state.durationPicker;

    const initLocationAddress = this.getEitherLogicalValue(this.state.locationAddress.address, address);

    const aboutUsPars = String(aboutUs).replace(/^null$/, '').split("\r\n");

    const dotsArray = this.calculateDotsDimensions();

    const wideScreen = window.innerWidth > 1366;
    const showServicesCarouselArrows =
      services.length > this.isConditinalRendering(wideScreen, 3, 2);

    const currentSlots =
      services[this.state.buttonIndex]?.attributes.availabilities;

    return (
      <TypographyView>
        <Box style={styles.SafeAreaView} className="landing-venue-block">
          <AppHeader context={this} />
          <Box className="search-location-block" py={6} px="140px">
            <Box display="flex">
              <BreadCrumbs
                items={["Home", "Spa Profile", "Business Information"]}
                targetColor="#398378"
                navigationMap={this.navigationMap}
                navigate={this.props.navigation.navigate}
              />
            </Box>
            <Typography
              style={{
                color: "#0F172A",
                fontFamily: "Ubuntu",
                fontSize: "48px",
                fontWeight: "700",
                padding: "30px 0",
              }}
            >
              {SPAVenueName}
            </Typography>
            <Box
              p="12px 24px"
              maxWidth="35%"
              minWidth={"453px"}
              display="flex"
              justifyContent={"space-between"}
              borderRadius="30px"
              bgcolor="#ffffff"
              whiteSpace="nowrap"
              mb="14px"
              style={styles.lightBorder}
            >
              <Box display={"flex"} alignItems={"center"} width="100%">
                <Box
                  p={1}
                  borderRadius="50%"
                  bgcolor="#FEF3C7"
                  display="inline-block"
                  mr="14px"
                  maxHeight='36px'
                >
                  <img src={bussiness_location} alt="map marker" />
                </Box>
                <Typography
                  data-testId="spa-address"
                  style={{
                    ...styles.regularMiddle,
                    overflowWrap: "break-word",
                    whiteSpace: "break-spaces",
                    display: "inline-block",
                    width: "80%",
                  }}
                >
                  {address}
                </Typography>
              </Box>
              <Box
                test-dataId="spa-location"
                p={1}
                className="buttonCursor"
                display="inline-block"
                onClick={() => this.checkRestriction('isEditLocationPopup', true)}
              >
                <img
                  src={editIcon}
                  alt="map marker"
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" mb="14px">
              <Box
                p="12px 24px"
                minWidth={"376px"}
                width="fit-content"
                borderRadius="30px"
                bgcolor="#ffffff"
                whiteSpace="nowrap"
                mb="14px"
                display="flex"
                alignItems="center"
                style={styles.lightBorder}
              >
                <Box borderRadius="50%" mr="14px">
                  <img src={this.getStatusImg()} alt="map marker" />
                </Box>
                <Typography
                  style={{
                    ...styles.regularMiddle,
                    ...styles.dotsOverflow,
                    width: "73%",
                  }}
                >
                  {this.getStatusNode()}
                </Typography>
                <Box
                  data-testId="Edit_Location"
                  p={1}
                  display="inline-block"
                  mr="14px"
                  onClick={this.handleEditSpaTimingPopup}
                >
                  <img
                    src={editIcon}
                    alt="map marker"
                    style={{ cursor: "pointer" }}
                  />
                </Box>
              </Box>
            </Box>
            <Box display="flex" alignItems="end" style={{ gap: 16 }} mb="60px">
              <Box
                style={{
                  ...styles.uploadBox,
                  ...styles.lightBorder,
                  boxSizing: "content-box",
                }}
              >
                <Box style={{ display: "flex", gap: 12, alignItems: "center" }}>
                  <Box
                    style={{
                      boxSizing: "border-box",
                      width: 56,
                      height: 56,
                      background: "#E8F6F4",
                      padding: "16px",
                    }}
                  >
                    <img src={uploadImg} />
                  </Box>
                  <Typography
                    style={{
                      fontFamily: "Ubuntu",
                      fontSize: "16px",
                      fontWeight: 400,
                      textAlign: "left",
                    }}
                  >
                    License
                  </Typography>
                </Box>
              </Box>
              <Box
                style={{
                  ...styles.uploadBox,
                  ...styles.lightBorder,
                  boxSizing: "content-box",
                }}
              >
                <Box style={{ display: "flex", gap: 12, alignItems: "center" }}>
                  <Box
                    style={{
                      boxSizing: "border-box",
                      width: 56,
                      height: 56,
                      background: "#E8F6F4",
                      padding: "16px",
                    }}
                  >
                    <img src={uploadImg} />
                  </Box>
                  <Typography
                    style={{
                      fontFamily: "Ubuntu",
                      fontSize: "16px",
                      fontWeight: 400,
                      textAlign: "left",
                    }}
                  >
                    Certificate
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Button
                  data-test-id="licenses-update"
                  style={{
                    padding: "10px 16px 10px 16px",
                    gap: "8px",
                    borderRadius: "6px",
                    background: "#398378",
                    color: "#FFFFFF",
                  }}
                  onClick={() => this.checkRestriction('isLicenseCertificationPopup', true)}
                >
                  Update
                </Button>
              </Box>
            </Box>
            {carouselImages?.length
              ? this.getTernaryNodeOutput(
                this.state.carouselLoading,
                <Spinner />,
                <CarouselProvider
                  touchEnabled
                  dragEnabled
                  naturalSlideWidth={1162}
                  naturalSlideHeight={558}
                  totalSlides={carouselImages.length}
                  currentSlide={carouselImages.length - 1}
                  className="venue-carousel"
                >
                  <Slider>
                    {(carouselImages as ImgObj[]).map((image, index) => (
                      <Slide
                        test-dataId="carousol-img"
                        index={index}
                        key={image.id}
                        onClick={(event) =>
                          this.onSliderClick(
                            event,
                            this.backBtnRef.current,
                            this.nextBtnRef.current
                          )
                        }
                        style={{
                          height: "558px",
                        }}
                      >
                        <Image
                          hasMasterSpinner={true}
                          src={image.url}
                          alt="venue image"
                          style={{
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                        <Box
                          data-test-id="add-img-block"
                          position={"absolute"}
                          display={"flex"}
                          top={15}
                          right={0}
                          p={"16px"}
                          onClick={(
                            event: React.MouseEvent<HTMLInputElement>
                          ) => event.stopPropagation()}
                        >
                          <Button
                            data-test-id="add-carousel-img"
                            component="label"
                            variant="text"
                            style={{
                              color: "#FFFFFF",
                              fontFamily: "Ubuntu",
                              fontSize: 16,
                              fontWeight: 700,
                              padding: 0,
                              marginRight: "16px",
                            }}
                          >
                            <img
                              src={addWhiteIcon}
                              style={{ marginRight: 8 }}
                            />
                            <Typography
                              style={{
                                fontFamily: "Ubuntu",
                                fontSize: 16,
                                fontWeight: 700,
                                color: "#FFFFFF",
                              }}
                            >
                              Add Images
                            </Typography>
                            <input
                              accept="image/*"
                              id="inputinuserblock"
                              data-test-id="add-carousel-image-icon"
                              className="imgContainer"
                              onChange={(
                                event: ChangeEvent<HTMLInputElement>
                              ) => this.handleUploadImage(event.target.files)}
                              onClick={() => this.handleUserAction()}
                              type={this.getCorrectInputType()}
                              style={{
                                width: 0,
                                visibility: "hidden",
                              }}
                            />
                          </Button>
                          <Box borderLeft={"1px solid #fff"}>
                            <img
                              data-testId="carousol-img-delete"
                              src={deleteWhiteIcon}
                              width="24px"
                              height="24px"
                              style={{
                                margin: "0px 12px",
                                cursor: "pointer",
                                width: "24px",
                                height: "24px",
                              }}
                              onClick={() =>
                                this.handleDeleteDocument(
                                  image.id,
                                  "carousal_images"
                                )
                              }
                            />
                          </Box>
                        </Box>
                      </Slide>
                    ))}
                  </Slider>

                  {/* <DotGroup /> */}
                  <Box
                    style={{
                      position: "absolute",
                      bottom: "43px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      display: "flex",
                      alignItems: "center",
                      gridGap: "8px",
                    }}
                  >
                    {(carouselImages as ImgObj[]).map((_, index) => {
                      const dimension = `${dotsArray[index]}px`;
                      return (
                        <Dot
                          data-test-id="carousel-dot"
                          key={index}
                          slide={index}
                          onClick={() =>
                            this.setState({ venueCarouselIndex: index })
                          }
                          style={{
                            width: dimension,
                            height: dimension,
                          }}
                        />
                      );
                    })}
                  </Box>
                  <Box
                    display="flex"
                    style={{ opacity: "0" }}
                    className="venue-search"
                  >
                    <ButtonBack>
                      <div ref={this.backBtnRef} />
                    </ButtonBack>
                    <ButtonNext>
                      <div ref={this.nextBtnRef} />
                    </ButtonNext>
                  </Box>
                </CarouselProvider>
              )
              : this.getTernaryNodeOutput(
                this.state.carouselLoading,
                <Spinner />,
                <Box
                  data-test-id="drag-item"
                  bgcolor="#ffffff"
                  borderRadius="2px"
                  style={{
                    padding: "50px 100px",
                    height: "558px",
                  }}
                  onDrop={(event: React.DragEvent) => {
                    event.preventDefault();
                    this.handleUploadImage(event.dataTransfer.files);
                  }}
                  onDragOver={(event: React.DragEvent) =>
                    event.preventDefault()
                  }
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      border: "3px dashed #64748B",
                      borderRadius: "16px",
                      padding: "25px 50px",
                      height: "100%",
                    }}
                  >
                    <Box
                      style={{
                        height: 150,
                        gap: 8,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <img src={fileUploadImg} />
                      </Box>
                      <Box
                        style={{
                          gap: 4,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          style={{
                            textAlign: "center",
                            fontFamily: "Ubuntu",
                            color: "#334155",
                            fontSize: 16,
                            fontWeight: 700,
                          }}
                        >
                          Upload Images
                        </Typography>
                        <Typography
                          style={{
                            color: "#334155",
                            fontSize: 14,
                            fontWeight: 400,
                          }}
                        >
                          Drag and drop the files here
                        </Typography>
                      </Box>

                      <Button
                        component="label"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: 12,
                          alignItems: "center",
                          padding: "16px 10px",
                          background: "#F1F5F9",
                          color: "#398378",
                          borderRadius: 8,
                          height: 44,
                          border: "1px solid #CBD5E1",
                          fontFamily: "Ubuntu",
                          fontSize: 16,
                          fontWeight: 700,
                        }}
                      >
                        <img src={addIcon} style={{ marginRight: 8 }} />{" "}
                        <Typography
                          style={{
                            fontFamily: "Ubuntu",
                            fontSize: 16,
                            fontWeight: 700,
                            color: "#398378",
                          }}
                        >
                          Add Images
                        </Typography>
                        <input
                          accept="image/*"
                          id="inputinuserblock"
                          data-test-id="add-carousel-image-input"
                          className="imgContainer"
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            this.handleUploadImage(event.target.files)
                          }
                          type="file"
                          style={{
                            width: 0,
                            visibility: "hidden",
                          }}
                        />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}

            {/* services carousel */}
            <Box mt="60px" display="flex" justifyContent="space-between">
              <Box width="60%">
                <Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography
                      style={{
                        fontSize: "30px",
                        fontWeight: "700",
                        color: "#000000",
                      }}
                    >
                      Spa Services
                    </Typography>
                    <Box
                      style={{
                        padding: "8px 16px",
                        backgroundColor: "#D9EEEB",
                        width: "56px",
                        height: "44px",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                      onClick={this.handleNavigationToEditService}
                    >
                      <img src={editIcon} alt="map marker" />
                    </Box>
                  </Box>

                  <Box style={styles.serviceBlock}>
                    <CarouselProvider
                      touchEnabled
                      dragEnabled
                      naturalSlideWidth={659}
                      naturalSlideHeight={68}
                      totalSlides={services.length}
                      visibleSlides={this.isConditinalRendering(
                        wideScreen,
                        3,
                        2
                      )}
                      isIntrinsicHeight
                      className="venue-service-carousel"
                    >
                      <Slider
                        classNameTrayWrap="buttons-carosel-tray"
                        style={{
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        {services.map((service, index) => {
                          const isActive = index === this.state.buttonIndex;
                          const {
                            id,
                            attributes: { name },
                          } = service;
                          return (
                            <Slide index={index} key={id}>
                              <Button
                                data-service-id="service-slide-button"
                                onClick={() =>
                                  this.setState({
                                    buttonIndex: index,
                                    datesRange: Array.from(
                                      new Array(50),
                                      () => []
                                    ),
                                  })
                                }
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  padding: "16px",
                                  borderRadius: "50px",
                                  border: "1px solid #FFFFFF",
                                  color: this.isConditinalRendering(
                                    isActive,
                                    "#398378",
                                    "#64748B"
                                  ),
                                  ...this.isAndConditionRendering(isActive, {
                                    backgroundColor: "#E8F6F4",
                                    border: "1px solid #D9EEEB",
                                  }),
                                }}
                              >
                                {name}
                              </Button>
                            </Slide>
                          );
                        })}
                      </Slider>
                      {this.isAndConditionRendering(
                        showServicesCarouselArrows,
                        <Box whiteSpace="nowrap">
                          <ButtonBack>
                            <Box style={styles.carouselButton}>
                              <img
                                src={imgCarouselArrowBack}
                                alt="arrow-back"
                              />
                            </Box>
                          </ButtonBack>
                          <ButtonNext>
                            <Box style={styles.carouselButton}>
                              <img
                                src={imgCarouselArrowNext}
                                alt="arrow-next"
                              />
                            </Box>
                          </ButtonNext>
                        </Box>
                      )}
                    </CarouselProvider>
                  </Box>
                  {/* We need to wait while openTiming is appeared */}
                  {openTiming && currentSlots?.map((slot, index) => {
                    const duration = slot.duration.duration;
                    const price = slot.duration.price;

                    const isInitialSlide = !this.state.datesRange[this.state.buttonIndex][index];

                    const resOpenTiming = this.getOpenTiming(openTiming, futureOpenTiming);

                    const today = new Date();
                    const startDate = today.toISOString();
                    const endDate = new Date(new Date().setDate(today.getDate() + 90)).toISOString();

                    const copyDatesRange = this.state.datesRange;

                    const datesRange = generateDatesRange(startDate, endDate);
                    const scheduleSlots = calculateSlots(
                      resOpenTiming,
                      duration,
                      {
                        timeSlots: slot.timeslots,
                        dates: slot.dates,
                        currentDate: datesRange[this.state.currentSlotIndex],
                      },
                      isInitialSlide
                    );

                    this.firstLoad.current = true;

                    if (isInitialSlide) {
                      // Set initial slide for each of slots popover
                      copyDatesRange[this.state.buttonIndex][index] = [renderDatarange(0, scheduleSlots)];
                      this.setState({ datesRange: copyDatesRange })
                    }

                    const datesRangeComponent = this.state.datesRange[this.state.buttonIndex][index];

                    const applyNextSlide = () => {
                      if (this.state.currentSlotIndex < datesRange.length) {
                        const resIndex = this.state.currentSlotIndex + 1;
                        const nextSlide = renderDatarange(resIndex, scheduleSlots);

                        // Set each next slide separatelly to prevent performance issue  
                        copyDatesRange[this.state.buttonIndex][index].push(nextSlide);
                        this.setState({
                          currentSlotIndex: this.state.currentSlotIndex + 1,
                          datesRange: copyDatesRange,
                        });
                      }
                    }

                    const applyPrevSlide = () => this.reduceSlotIndex();

                    return <Box key={slot.availability_id} style={styles.serviceBlock}>
                      <Box pt='12px' display='flex' justifyContent='flex-start'>
                        <Box display='flex' alignItems='center' flexBasis='25%'>
                          <Box p={1} borderRadius='50%' bgcolor='#D1FAE5' display='inline-block' mr='14px' maxHeight='36px'>
                            <img src={imgPendingClock} alt='map marker' />
                          </Box>
                          <Typography
                            style={{
                              fontSize: '14px',
                              color: '#0F172A'
                            }}
                          >
                            {duration}
                          </Typography>
                        </Box>
                        <Box display='flex' alignItems='center' ml={3}>
                          <Box p={1} borderRadius='50%' bgcolor='#FEF3C7' display='inline-block' mr='14px' maxHeight='36px'>
                            <img src={imgDollarSign} alt='map marker' />
                          </Box>
                          <Typography
                            style={{
                              fontSize: '14px',
                              color: '#0F172A'
                            }}
                          >
                            {this.currency} {price}
                          </Typography>
                        </Box>
                      </Box>
                      <Button
                        data-test-id='open-booking-popover-btn'
                        endIcon={
                          <img src={imgArrowDownTriangle} alt='arrow down' />
                        }
                        style={{
                          marginTop: '16px',
                          padding: '5px 12px',
                          fontSize: '12px',
                          color: '#fff',
                          borderRadius: '8px',
                          backgroundColor: '#398378',
                          fontWeight: '700'
                        }}
                        onClick={(event) => {
                          this.setState({ anchorSlots: event.currentTarget });
                          this.setState({ currentPopperIndex: index })
                        }}
                      >
                        Book Slots
                      </Button>
                      <Popover
                        data-test-id='booking-schedule-popover'
                        open={this.isTargetPopover(index)}
                        onClose={() => {
                          this.setState({ anchorSlots: null });
                          this.setState({ currentSlotIndex: 0 });
                        }}
                        anchorEl={this.state.anchorSlots}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 21,
                        }}
                        style={popperStyle}
                        PaperProps={{
                          style: { width: '500px' },
                        }}
                      >
                        <Box p={3} >
                          <CarouselProvider
                            naturalSlideWidth={500}
                            naturalSlideHeight={164}
                            totalSlides={datesRange.length}
                            visibleSlides={1}
                            isIntrinsicHeight
                            className='venue-schedule-carousel'
                          >
                            <Box display='flex' gridGap='5px' alignItems='center'>
                              <Box>
                                <ButtonBack onClick={applyPrevSlide} style={styles.scheduleArrow} >
                                  <KeyboardArrowLeft style={{ width: '18px' }} />
                                </ButtonBack>
                                <ButtonNext onClick={applyNextSlide} style={styles.scheduleArrow} >
                                  <KeyboardArrowRight style={{ width: '18px' }} />
                                </ButtonNext>
                              </Box>
                              <Typography
                                style={{
                                  ...styles.boldSmall,
                                  color: '#000000'
                                }}
                              >
                                {datesRange[this.state.currentSlotIndex]}
                              </Typography>
                            </Box>

                            <Slider
                              classNameTray='schedule-tray'
                              style={{
                                display: 'flex',
                                margin: '28px 0'
                              }}>
                              {datesRangeComponent}
                            </Slider>
                          </CarouselProvider>
                        </Box>
                      </Popover>
                    </Box>
                  })}

                  <Typography
                    style={{
                      width: "fit-content",
                      margin: "35px auto auto",
                    }}
                  >
                    {configJSON.slotsNotAvailable}
                  </Typography>
                </Box>
              </Box>
              <Box width="38%">
                <Box>
                  <Typography
                    style={{
                      ...styles.boldSmall,
                      color: "#0F172A",
                      marginBottom: "8px",
                    }}
                  >
                    ABOUT
                  </Typography>
                  {this.state.aboutUs === undefined ? (
                    aboutUsPars.map((aboutPar, index) => (
                      <Typography
                        key={index}
                        style={{
                          marginTop: "16px",
                          fontSize: "14px",
                          color: "#334155",
                        }}
                      >
                        {aboutPar}
                      </Typography>
                    ))) : (
                    <TextField
                      data-test-id="about-us-input"
                      fullWidth
                      multiline
                      value={this.state.aboutUs}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        this.setAboutUs(event.target.value)
                      }
                      variant="outlined"
                      className="inputeStyle"
                      placeholder={"Add About Us"}
                    />
                  )}

                  {this.state.aboutUs ? (
                    <Button
                      data-test-id="about-us-submit"
                      className="editButtonServices"
                      style={{
                        ...styles.blockButton,
                        background: "#398378",
                        color: "#FFFFFF",
                      }}
                      onClick={() => {
                        this.updateProfileProperties({
                          "account[profile][bio]": String(this.state.aboutUs),
                        });
                        this.setAboutUs();
                      }}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      data-test-id="edit-aboutus"
                      className="editButtonServices"
                      style={styles.blockButton}
                      onClick={() => this.checkRestriction('aboutUs', aboutUs)}
                    >
                      Edit
                    </Button>
                  )}
                </Box>
                <Typography
                  style={{
                    ...styles.boldSmall,
                    marginBottom: "4px",
                    marginTop: "40px",
                  }}
                >
                  LOCATION
                </Typography>

                <Box
                  p="12px"
                  borderRadius="30px"
                  bgcolor="#ffffff"
                  whiteSpace="nowrap"
                  mb="14px"
                >
                  <Box
                    p={1}
                    borderRadius="50%"
                    bgcolor="#FEF3C7"
                    display="inline-block"
                    mr="14px"
                    maxHeight='36px'
                  >
                    <img src={imgLocationSearch} alt="map marker" />
                  </Box>
                  <Typography
                    style={{
                      ...styles.regularMiddle,
                      ...styles.dotsOverflow,
                      display: "inline-block",
                      width: "85%",
                    }}
                  >
                    {address}
                  </Typography>
                </Box>
                <Box display='flex' justifyContent='center' alignItems='center' height='400px'>
                  {this.getTernaryValue(
                    Boolean(address),
                    <MapLocation address={address} />,
                    <CircularProgress style={{ color: '#398378' }} />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <ModalWrapperComponent
            isOpen={this.state.isLicenseCertificationPopup}
            modalHeading="License and Certifications"
            handleClose={this.handleCloseLicenseUploadPopup}
            width={"50%"}
          >
            <TypographyView>
              <Box className="modalBOdyContainer">
                <Box
                  style={{ maxHeight: "60vh", overflow: "auto", gap: "0px" }}
                  className="modalBodyMain"
                >
                  {createUploaders.call(
                    this,
                    (
                      number: number,
                      resultFileError: string,
                      resultSetter: any
                    ) => (
                      <Box key={number}>
                        <Snackbar
                          data-test-id="error-snackbar"
                          open={Boolean(resultFileError)}
                          message={resultFileError}
                          key={"top" + "right"}
                          style={{ background: "#fee2e2" }}
                        />
                        {this.uploadFileItem({
                          stateSetter: resultSetter.setter,
                          titleText: resultSetter.title,
                          uploadedFiles: resultSetter.uploadedFile,
                          popoverAnchor: resultSetter.popoverAnchor,
                        })}
                      </Box>
                    )
                  )}
                </Box>
                <Box
                  style={{
                    borderTop: "1px solid #E2E8F0",
                    height: "104px",
                    borderRadius: "0px 0px 32px 8px",
                    padding: "24px",
                    display: "flex",
                    justifyContent: "end",
                    boxSizing: "border-box",
                  }}
                >
                  <Box width={"40%"} display={"flex"} style={{ gap: 24 }}>
                    <Button
                      data-testId="updatelicenseSavCancel"
                      style={{
                        width: "100%",
                        background: "#D9EEEB",
                        color: "#398378",
                      }}
                      className="buttonContainer"
                      onClick={this.handleCloseLicenseUploadPopup}
                    >
                      Cancel
                    </Button>
                    <Button
                      data-testId="updatelicenseSave"
                      style={{ width: "100%" }}
                      className="buttonContainer"
                      onClick={this.uploadDocuments}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Box>
            </TypographyView>
          </ModalWrapperComponent>

          <ModalWrapperComponent
            isOpen={this.state.isEditLocationPopup}
            modalHeading="Edit Location"
            handleClose={this.handleCancelEditLocation}
            width={"50%"}
          >
            <RegistrationFormView>
              <Box
                p={"0px 40px"}
                style={{
                  maxHeight: "80vh",
                  overflow: "auto",
                  overflowX: "hidden",
                }}
              >
                <Box>
                  <Box sx={{ flexDirection: "row", display: "flex" }}>
                    <InputLabel className="testLabel">
                      {"Spa Location"}{" "}
                    </InputLabel>
                    <InputLabel className="testStar">{"*"}</InputLabel>
                  </Box>
                  <Box
                    data-test-id='map-block'
                    height='234px'
                    width='100%'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    onMouseDown={() => this.goToLocaton()}
                  >
                    {initLocationAddress ?
                      <MapLocationAdv
                        customStyles={{
                          height: '234px',
                          width: '100%',
                          objectFit: 'cover',
                          cursor: 'pointer'
                        }}
                        address={initLocationAddress}
                      /> :
                      <CircularProgress style={{ color: '#398378' }} />}
                  </Box>
                </Box>
                <Box>
                  <Box sx={{ flexDirection: "row", display: "flex" }}>
                    <InputLabel className="testLabel">{"Country"} </InputLabel>
                    <InputLabel className="testStar">{"*"}</InputLabel>
                  </Box>
                  <Autocomplete
                    data-test-id="country-autocomplete"
                    fullWidth
                    options={this.state.countries.map(
                      (country) => country.name
                    )}
                    className="inputeStyle"
                    autoComplete
                    autoHighlight
                    disableClearable
                    disablePortal
                    value={this.state.country}
                    style={{
                      ...this.isAndConditionRendering(this.state.countryError, {
                        borderColor: "#DC2626",
                      }),
                    }}
                    onChange={(event: any) => this.onSelectCountry(event)}
                    popupIcon={
                      <img width={25} height={25} src={arrowDown} alt="" />
                    }
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder="Select country"
                        error={this.state.countryError}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          style: {
                            padding: "12px 16px",
                          },
                        }}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Box sx={{ flexDirection: "row", display: "flex" }}>
                    <InputLabel className="testLabel">{"State"} </InputLabel>
                    <InputLabel className="testStar">{"*"}</InputLabel>
                  </Box>
                  <Autocomplete
                    data-test-id="state-autocomplete"
                    fullWidth
                    options={this.getStateOptions()}
                    className="inputeStyle"
                    autoComplete
                    autoHighlight
                    disableClearable
                    disablePortal
                    value={this.state.stateValue}
                    style={{
                      ...this.isAndConditionRendering(this.state.stateError, {
                        borderColor: "#DC2626",
                      }),
                    }}
                    onChange={(event) => this.onSelectState(event)}
                    popupIcon={
                      <img width={25} height={25} src={arrowDown} alt="" />
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select state"
                        error={this.state.stateError}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          style: {
                            padding: "12px 16px",
                          },
                        }}
                      />
                    )}
                  />
                  {this.getLogicalValue(
                    this.state.stateError,
                    <CustomTypography>
                      <Typography className="txtErrorMsg">
                        {configJSON.stateErrorMsg}
                      </Typography>
                    </CustomTypography>
                  )}
                </Box>
                <Box>
                  <Box sx={{ flexDirection: "row", display: "flex" }}>
                    <InputLabel className="testLabel">{"City"} </InputLabel>
                    <InputLabel className="testStar">{"*"}</InputLabel>
                  </Box>
                  <Autocomplete
                    data-test-id="city-autocomplete"
                    fullWidth
                    options={this.getCityOptions()}
                    value={this.state.city}
                    className="inputeStyle"
                    autoComplete
                    autoHighlight
                    disableClearable
                    disablePortal
                    style={{
                      ...this.isAndConditionRendering(this.state.cityError, {
                        borderColor: "#DC2626",
                      }),
                    }}
                    onChange={(event) => this.onSelectCity(event)}
                    popupIcon={
                      <img width={25} height={25} src={arrowDown} alt="" />
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select city"
                        error={this.state.cityError}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          style: {
                            padding: "12px 16px",
                          },
                        }}
                      />
                    )}
                  />
                  {this.state.cityError && (
                    <CustomTypography>
                      <Typography className="txtErrorMsg">
                        {configJSON.cityErrorMsg}
                      </Typography>
                    </CustomTypography>
                  )}
                </Box>
                <Box>
                  <Box sx={{ flexDirection: "row", display: "flex" }}>
                    <InputLabel className="testLabel">
                      {"Complete Address"}{" "}
                    </InputLabel>
                    <InputLabel className="testStar">{"*"}</InputLabel>
                  </Box>
                  <TextField
                    data-test-id="spa-address"
                    fullWidth={true}
                    variant="outlined"
                    className="inputeStyle"
                    placeholder="Enter Complete Address"
                    value={this.state.spaAddress}
                    onChange={this.setAddress}
                  />
                  {this.getLogicalValue(this.state.addressError, (
                    <CustomTypography>
                      <Typography
                        data-testId="address-err"
                        className="txtErrorMsg"
                      >
                        Please enter the complete address
                      </Typography>
                    </CustomTypography>
                  ))}
                </Box>
                <Box
                  style={{
                    borderTop: "1px solid #E2E8F0",
                    height: "104px",
                    borderRadius: "0px 0px 32px 8px",
                    padding: "24px",
                    display: "flex",
                    justifyContent: "end",
                    boxSizing: "border-box",
                    paddingRight: 0,
                  }}
                >
                  <Box width={"40%"} display={"flex"} style={{ gap: 24 }}>
                    <Button
                      data-testId="cancel-location"
                      style={{
                        width: "100%",
                        background: "#D9EEEB",
                        color: "#398378",
                      }}
                      className="buttonContainer"
                      onClick={this.handleCancelEditLocation}
                    >
                      Cancel
                    </Button>
                    <Button
                      data-testId="update-location"
                      style={{ width: "100%" }}
                      className="buttonContainer"
                      onClick={this.handleUpdateSpaProfileAddres}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Box>
            </RegistrationFormView>
          </ModalWrapperComponent>
          <ModalWrapperComponent
            isOpen={this.state.isSpaTimingPopup}
            modalHeading="Spa Timing"
            handleClose={this.handleCancelSpatimingPopUp}
            width={"50%"}
            styles={{
              overflowY: "auto",
              maxHeight: "100%",
              gap: "unset",
            }}
          >
            <RegistrationFormView>
              <Snackbar
                data-test-id="time-error-snackbar"
                open={Boolean(this.state.apiError)}
                autoHideDuration={6000}
                message={configJSON.apiErrorMessage}
                key={"top" + "right"}
                style={{
                  background: "#fee2e2",
                  position: "absolute",
                  left: "-200px",
                }}
                onClose={() => this.setApiError(false)}
              />
              <Box p={"0px 40px"}>
                <Box sx={{ flexDirection: "row", display: "flex" }}>
                  <InputLabel className="testLabel">{"Spa Name"} </InputLabel>
                </Box>
                <TextField
                  data-test-id="spa_name"
                  fullWidth={true}
                  variant="outlined"
                  className="inputeStyle"
                  style={{ color: "#334155", fontSize: 16, lineHeight: 24 }}
                  placeholder="Spa Name"
                  value={SPAVenueName}
                  disabled
                />
                <Box
                  sx={{
                    flexDirection: "row",
                    display: "flex",
                    paddingTop: "15px",
                  }}
                >
                  <InputLabel className="testLabel">
                    {"Today Status"}{" "}
                  </InputLabel>
                </Box>
                <Autocomplete
                  data-test-id="spatiming-autocomplete"
                  fullWidth
                  options={["OPEN", "CLOSED", "PARTIALLY CLOSED"]}
                  className="inputeStyle"
                  autoComplete
                  autoHighlight
                  disableClearable
                  disablePortal
                  value={this.state.spaTodayStatus}
                  style={{
                    ...(this.state.countryError && { borderColor: "#DC2626" }),
                    color: this.SpaTodatStatusOption(this.state.spaTodayStatus),
                  }}
                  onChange={(event: any) =>
                    this.setState({ spaTodayStatus: event.target.textContent })
                  }
                  getOptionLabel={this.getOptionLabel}
                  getOptionSelected={this.getOptionSelected}
                  popupIcon={
                    <img width={25} height={25} src={arrowDown} alt="" />
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Today Status"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        style: {
                          padding: "12px 16px",
                          fontFamily: "Ubuntu",
                          fontWeight: 700,
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: this.SpaTodatStatusOption(
                            this.state.spaTodayStatus
                          ),
                        },
                      }}
                    />
                  )}
                  renderOption={(option) => (
                    <MenuItem
                      style={{
                        fontFamily: "Ubuntu",
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: this.SpaTodatStatusOption(option),
                      }}
                    >
                      {option}
                    </MenuItem>
                  )}
                />
                <Box width="72%">
                  <Box
                    {...styles.clickBlock}
                    p="12px 24px"
                    maxWidth="520px"
                    minHeight="76px"
                    borderRadius="50px"
                    border="1px solid #CBD5E1"
                    bgcolor="#ffffff"
                    whiteSpace="nowrap"
                    mb="16px"
                    mt="16px"
                    position="relative"
                  >
                    <Box
                      p={1}
                      borderRadius="50%"
                      bgcolor="#D1FAE5"
                      display="inline-block"
                      mr="14px"
                      maxHeight='36px'
                    >
                      <img src={imgPendingClock} alt="map marker" />
                    </Box>
                    <Typography
                      style={{
                        ...styles.regularMiddle,
                        ...styles.dotsOverflow,
                        display: "inline-block",
                      }}
                    >
                      Todays timing{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {this.state.updatedSpaTiming}
                      </span>
                    </Typography>
                    <Box
                      p={1}
                      display="inline-block"
                      mr="14px"
                      position="absolute"
                      maxHeight='36px'
                      right={0}
                    >
                      <img
                        data-test-id="timing-popover-img"
                        src={arrowDown}
                        style={{ cursor: "pointer" }}
                        onClick={(event: React.MouseEvent<HTMLImageElement>) =>
                          this.setState({ anchorEl: event.currentTarget })
                        }
                      />
                      <CustomPopover
                        data-test-id="spa-time-popover"
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.closeTimePopOver}
                        anchorEl={this.state.anchorEl}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        PaperProps={{
                          style: {
                            marginTop: 48,
                            marginLeft: 18,
                            width: "220px",
                            boxShadow: "none",
                            borderRadius: "8px",
                            border: "1px solid #CBD5E1",
                          },
                        }}
                      >
                        <div
                          style={{
                            border: "1px solid #CBD5E1",
                            borderRadius: 4,
                            padding: 4,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Box bgcolor="#FFFFFF" width={"220px"}>
                            {spaTimingSlots.map((item) => (
                              <Box
                                key={item.label}
                                test-data-id="spa-timing-slot"
                                className="addTimingOpen"
                                display="flex"
                                alignItems="center"
                                p={"12px 16px"}
                                style={{
                                  cursor: "pointer",
                                  gap: 8,
                                }}
                                onClick={() => this.handelSpatimingSlot(item)}
                              >
                                <img src={clockIcon} />
                                <Typography
                                  style={{ fontFamily: "Ubuntu", fontSize: 14 }}
                                >
                                  {item.label}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </div>
                      </CustomPopover>
                    </Box>
                  </Box>
                  <Box>
                    <ModalWrapperComponent
                      data-test-id="calendar-prompt"
                      withoutHeader
                      isOpen={this.state.showCalendarPopUp}
                      handleClose={() => this.setCalendarPopUp(false)}
                      styles={{
                        position: "relative",
                        height: "70%",
                        width: "35%",
                        borderRadius: "0 0 32px 0",
                        padding: "16px 32px 16px",
                        boxSizing: "border-box",
                        textAlign: "center",
                      }}
                      closeStyles={{
                        position: "absolute",
                        right: "16px",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "20px",
                          fontWeight: "700",
                        }}
                      >
                        Set your Date
                      </Typography>
                      <Box margin="0 auto" width="100%">
                        {/* minDate property causes issue when date rage is not started from today */}
                        <DatePicker
                          data-test-id="date-picker"
                          name="name"
                          className="basic-page-picker"
                          multiple
                          range
                          buttons
                          monthYearSeparator=" "
                          weekStartDayIndex={1}
                          showOtherDays
                          render={(_, openCalendar) => {
                            if (this.state.openCalendar) {
                              this.calendarLinkRef.current?.click();
                              this.calendarLinkRef.current = null;
                            } else {
                              this.setState({
                                openCalendar: true,
                                showCalendarPopUp: false,
                              });
                            }
                            return (
                              <div
                                onClick={openCalendar}
                                ref={this.calendarLinkRef}
                              >
                                Pick your preferred date for opening Spa
                              </div>
                            );
                          }}
                          onChange={this.handleDates}
                          onClose={() => false}
                        />
                        <Button
                          data-test-id="save-calendar"
                          style={{
                            width: "75%",
                            position: "absolute",
                            bottom: "20px",
                            left: "50%",
                            transform: "translateX(-50%)",
                          }}
                          className="buttonContainer"
                          onClick={() => this.setCalendarPopUp(false)}
                        >
                          Save
                        </Button>
                      </Box>
                    </ModalWrapperComponent>
                    <Box
                      data-test-id="calendar-timing-block"
                      onClick={() => this.setCalendarPopUp(true)}
                      {...styles.clickBlock}
                    >
                      <Box
                        p={1}
                        borderRadius="50%"
                        bgcolor="#FEF3C7"
                        display="inline-block"
                        mr="14px"
                        maxHeight='36px'
                      >
                        <img
                          data-test-id="add-timing-img"
                          src={AddTimingIcon}
                          alt="map marker"
                          style={{ cursor: "pointer" }}
                        />
                      </Box>
                      <Typography
                        style={{
                          ...styles.regularMiddle,
                          ...styles.dotsOverflow,
                          display: "inline-block",
                        }}
                      >
                        {this.getTrullyVal(this.state.openingDates, "Add Date")}
                      </Typography>
                      <Box
                        p={1}
                        className="buttonCursor"
                        display="inline-block"
                        mr="14px"
                        position="absolute"
                        right={0}
                      >
                        <img
                          data-test-id="calendar-icon-btn"
                          src={calendarIcon}
                          alt="map marker"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box {...styles.clickBlock}>
                    <Box
                      p={1}
                      borderRadius="50%"
                      bgcolor="#FEF3C7"
                      display="inline-block"
                      mr="14px"
                    >
                      <img
                        data-test-id="add-timing-img"
                        src={AddTimingIcon}
                        alt="map marker"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.setOpenPrefferedTiming(true)}
                      />
                    </Box>
                    <Typography
                      style={{
                        ...styles.regularMiddle,
                        ...styles.dotsOverflow,
                        display: "inline-block",
                      }}
                    >
                      {this.getCommonDuration("Add timings")}
                    </Typography>
                    <Box
                      p={1}
                      className="buttonCursor"
                      display="inline-block"
                      mr="14px"
                      position="absolute"
                      right={0}
                    >
                      <img
                        data-test-id="timing-icon-btn"
                        src={timerClock}
                        alt="map marker"
                        onClick={() =>
                          this.setState({ addPrefferTiming: true })
                        }
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    data-test-id="same-time-checkbox"
                    onClick={this.setSameTimeChecked}
                    checked={this.state.sameTimeChecked}
                    className="sametimeAllDay"
                  />
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontFamily: "Ubuntu",
                      fontSize: 14,
                      color: "#64748B",
                    }}
                  >
                    Same timings all days of week
                  </Typography>
                </Box>
              </Box>
            </RegistrationFormView>

            <Box className="multiButtonContainer">
              <Box display={"flex"} width={"40%"} style={{ gap: 24 }}>
                <Button
                  test-dataId="spa-timing-close"
                  style={{
                    background: "#D9EEEB",
                    width: "100%",
                    color: "#398378",
                  }}
                  className="buttonContainer"
                  onClick={this.handleCancelSpatimingPopUp}
                >
                  Cancel
                </Button>
                <Button
                  data-test-id="submit-timing-form"
                  style={{ width: "100%" }}
                  className="buttonContainer"
                  onClick={this.submitTimingForm}
                >
                  Done
                </Button>
              </Box>
            </Box>
          </ModalWrapperComponent>
          <Modal open={this.state.addPrefferTiming}>
            <ModalWrapper style={{ paddingTop: 0, width: 443 }}>
              <Box
                id="form-dialog-title"
                className="ModalHeader"
                position={"relative"}
                justifyContent={"center"}
                style={{
                  borderBottom: "none",
                  paddingBottom: 16,
                }}
              >
                <Typography
                  className="modalHeadingText"
                  style={{
                    fontSize: "20px",
                    margin: "auto",
                  }}
                >
                  Add Time
                </Typography>
                <Box
                  data-test-id="close-duration"
                  position="absolute"
                  top="20px"
                  right="20px"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.setOpenPrefferedTiming(false)}
                >
                  <img src={closeIcon} />
                </Box>
              </Box>
              <Typography style={{ textAlign: "center" }}>
                Pick a preferred timing for the day
              </Typography>
              <Box
                mt={2}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                style={{ gap: 32 }}
              >
                <Box
                  data-test-id="duration-time-from"
                  style={{
                    cursor: "pointer",
                    color: this.getTimeColor({
                      isFromTime: this.state.isDurationFrom,
                    }),
                  }}
                  onClick={() => this.setIsDurationFrom(true)}
                >
                  <span
                    style={{
                      fontFamily: "Ubuntu",
                      fontSize: 30,
                    }}
                  >
                    {durationFrom.hours}:{durationFrom.minutes}
                  </span>
                  <span
                    style={{
                      fontFamily: "Ubuntu",
                      fontSize: 16,
                      marginLeft: 4,
                    }}
                  >
                    AM
                  </span>
                </Box>
                <img src={timeRangeArrow} />
                <Box
                  data-test-id="duration-time-to"
                  style={{
                    cursor: "pointer",
                    color: this.getTimeColor({
                      isFromTime: !this.state.isDurationFrom,
                    }),
                  }}
                  onClick={() => this.setIsDurationFrom(false)}
                >
                  <span
                    style={{
                      fontFamily: "Ubuntu",
                      fontSize: 30,
                    }}
                  >
                    {durationTo.hours}:{durationTo.minutes}
                  </span>
                  <span
                    style={{
                      fontFamily: "Ubuntu",
                      fontSize: 16,
                      marginLeft: 4,
                    }}
                  >
                    PM
                  </span>
                </Box>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box
                  position={"relative"}
                  display={"flex"}
                  overflow={"hidden"}
                  height={"142px"}
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <div
                    className="duration-picker-container"
                    style={{ position: "relative" }}
                  >
                    <DurationPicker
                      data-test-id="duration-picker"
                      maxHours={12}
                      onChange={this.onDurationChange}
                    />
                  </div>
                </Box>
              </Box>
              <Box mb={2} display={"flex"} justifyContent={"center"}>
                <Button
                  data-test-id="duration-save-btn"
                  style={{
                    background: "#398378",
                    color: "#FFFFFF",
                    width: 320,
                    height: 56,
                    borderRadius: 8,
                    textTransform: "none",
                  }}
                  onClick={() => this.setOpenPrefferedTiming(false)}
                >
                  Save
                </Button>
              </Box>
            </ModalWrapper>
          </Modal>
          {/* footer */}
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={!!this.state.editMessage}
            onClose={this.onSuccessMessageClose}
            autoHideDuration={5000}
          >
            <Box
              display="flex"
              bgcolor="#FFF"
              borderRadius="8px"
              px="1rem"
              py="0.5rem"
              style={{ gap: "1rem" }}
            >
              <img src={successMessageIcon} />
              <Typography>{this.state.editMessage}</Typography>
            </Box>
          </Snackbar>
          <AppFooter navigation={this.props.navigation} />
        </Box>
        <PlanExpiryPrompt context={this} userAction={this.state.userAction} />
      </TypographyView>
    );
    // Customizable Area End
  }
}
// Customizable Area Start

export const CustomUploadingBar = (props: any) => {
  const { open, progress } = props;
  return (
    <>
      {Boolean(open) && (
        <div
          style={{
            marginTop: 20,
            position: "relative",
            width: "100%",
            textAlign: "center",
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              display: "flex",
              fontFamily: "ubuntu",
              fontSize: "12px",
              fontWeight: 400,
              alignItems: "center",
              gap: 12,
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "#D97706",
              zIndex: 9,
            }}
          >
            Uploading...{" "}
            <CircularProgress size={16} style={{ color: "#D97706" }} />
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <LinearProgress
              variant="determinate"
              value={progress}
              style={{
                width: "100%",
                height: 48,
                borderRadius: 4,
                backgroundColor: "#FEF3C7",
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default BasicInformation;
// Customizable Area End
