Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.getAPIMethod = "GET"
exports.userDetailsAPIEndPoint = "account_block/get_user_details";
exports.landingPageAPIEndPoint = "bx_block_dashboard4/landing_page_for_customer";
exports.userDetailsApiMethodType = "GET";
exports.landingPageApiMethodType = "GET";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.commonErrorText = "There is an error";
exports.commonErrorDescription = "Cannot retrieve data from server";
exports.reviewApiEndPoint = "bx_block_catalogue/reviews";
exports.cataloguesApiEndPoint = "bx_block_catalogue/catalogues";
exports.postCustomerBookingDetailsAPI="/bx_block_calendar/booked_slots"
exports.contentManagement = "bx_block_content_management/content_managements"


exports.createBookingsApiEndPoint = "bx_block_calendar/booked_slots";
exports.getSubCategories="bx_block_dashboard4/get_category_spas"
exports.searchSpaName="bx_block_advanced_search/search/filter"

exports.contactDetailsAPI="bx_block_contact_us/contacts"
exports.currentPlanAPI="account_block/current_plan"


exports.textHeading = 'Find a Service close to you';
exports.description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.';
exports.serviceConfirmation = 'Provided services should be confirmed';
exports.endPointGetSpaDetailsByCountry = 'bx_block_dashboard4/countries_with_max_booked_slots';
exports.endPointGetSpaDetailsBasedOnCountry= 'bx_block_dashboard4/spa_accounts_by_country'

exports.socialMediaIconsGet="account_block/social_accounts"

// Customizable Area End