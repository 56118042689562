Object.defineProperty(exports, '__esModule', {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = 'application/json';
exports.validationApiMethodType = 'GET';
exports.addCouponsCode = 'bx_block_coupon_cg/show_coupons';
exports.userDetailsApiMethodType = 'GET';
exports.appliedCouponAPIEndPoint = 'bx_block_coupon_cg/applied_coupon';
exports.getAllCouponsEndPoint = "bx_block_coupon_cg/all_coupons"
exports.appliedCouponApiMethodType = 'POST';
exports.userBookingAPIEndPoint = 'bx_block_calendar/booking_detail';
exports.bookingDetailsApiMethodType = 'GET';
exports.methodTypeApiGetUserProfile = 'GET';
exports.methodTypeApiGet = 'GET';
exports.exampleAPiEndPoint = 'EXAMPLE_URL';
exports.exampleAPiMethod = 'POST';
exports.exampleApiContentType = 'application/json';
exports.textInputPlaceHolder = 'Enter Text';
exports.labelTitleText = 'ordermanagement1';
exports.labelBodyText = 'ordermanagement1 Body';
exports.priceValidationError = 'Please enter valid price';
exports.btnExampleTitle = 'CLICK ME';
exports.createBookingsApiEndPoint = 'bx_block_calendar/booked_slots';
exports.userDetailsAPIEndPoint = 'account_block/get_user_details';
exports.userCatalogueAPIEndPoint = 'bx_block_catalogue/user_catalogues';
exports.bookingsAPIEndPoint = 'bx_block_calendar/my_booking';
exports.categoryApiEndPoint = 'bx_block_categories/categories';
exports.serviceApiEndPoint =
  'bx_block_catalogue/get_catalogue_by_category_subcat';
exports.cancelBookingApiEndPoint = 'bx_block_calendar/cancel_booking';
exports.rescheduleBookingApiEndPoint = 'bx_block_calendar/booked_slots';
exports.createBookingApiEndPoint = 'bx_block_calendar/booked_slots';
exports.scheduleApiEndPoint = 'bx_block_calendar/my_calender_bookings';
exports.completeBookingApiEndpoint = 'bx_block_calendar/completed_booking';
exports.currentSubscriptionApiUrl = "account_block/current_plan";
exports.completeBookingApiMethod = 'PUT';
exports.cancelBookingApiMethodType = 'PUT';
exports.rescheduleBookingApiMethodType = 'PATCH';
exports.cataloguesApiEndPoint = "bx_block_catalogue/catalogues"

exports.commonErrorText = "There is an error";
exports.commonErrorDescription = "Cannot retrieve data from server";
// Customizable Area End
