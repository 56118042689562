Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings";
exports.labelBodyText = "settings Body";
exports.emptyCountry = "Please enter the country";
exports.emptyState = "Please enter the state";
exports.emptyCity = "Please enter the city";
exports.emptyCompleteAddress = "Please enter the complete address";
exports.emptyServiceName = "Please enter the service name";
exports.emptyCategory = "Please enter the category";
exports.emptySubCategory = "Please enter sub category";
exports.emptyServiveDescription = "Please enter service description";
exports.emptyDuration = "Please enter duration";
exports.emptyPrice = "Please enter price";
exports.emptyDate = "Please choose date";
exports.emptyAvailableSlots = "Please choose slots for all durations";

exports.retrieveApiMethod = "GET";
exports.updateApiMethod = "PUT";
exports.saveApiMethod = "POST";
exports.deleteApiMethod = "DELETE";
exports.contentType = "application/json";
exports.categoryApiEndPoint = "bx_block_categories/categories";
exports.productApiEndPoint = "bx_block_catalogue/catalogues";
exports.productApiUpdateEndPoint = "bx_block_catalogue/update";
exports.regionApiEndPoint = "account_block/get_regions";
exports.profileApiEndPoint = "account_block/update_profile";
exports.userDetailsAPIEndPoint = "account_block/get_user_details";
exports.userCatalogueAPIEndPoint = "bx_block_catalogue/user_catalogues";
exports.deleteAttachmentsAPIEndPoint = "account_block/delete_attachment";
exports.currentSubscriptionApiUrl = "account_block/current_plan";


exports.commonErrorText = "There is an error";
exports.commonErrorDescription = "Cannot retrieve data from server";
// Customizable Area End