import React from "react";
// Customizable Area Start

import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  createTheme,
  ThemeProvider,
  CircularProgress,
} from "@material-ui/core";

import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import arLocale from '@fullcalendar/core/locales/ar';
import dayjs from "dayjs";
import './calendar.css';

import Ordermanagement1Controller, {
  Props,
} from "./Ordermanagement1Controller";
import {
  bookingSchedule,
  defaultProfile,
} from './assets';
import './select.css';
import { BookingWrapper } from './components/BookingWrapper';
import BookingModal from './components/BookingModal';
import { getRtlStyleValue, getTransContent, getTranslationConfig } from '../../../components/src/helpers';
import { PlanExpiryPrompt } from '../../../components/src/PlanExpiryPrompt';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Ubuntu",
    },
  },
});
// Customizable Area End

export default class Ordermanagement1 extends Ordermanagement1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.gotoMyBooking = this.gotoMyBooking.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { t, dir, isArabic } = getTranslationConfig();
    const { schedButtonPosition, elementFlip, selectArrow } = getRtlStyleValue(isArabic);

    const allServiceType = t['booking-schedule-all-services'];
    const scheduledCategoriesList = this.getScheduleCategoriesList(allServiceType, isArabic);

    !this.state.scheduleServiceType && this.setInitScheduleServiceType(allServiceType);

    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          <BookingWrapper
            heading={t['profile-my-bookings-title']}
            breadcrumbs={[
              t['breadcrumbs-home'],
              t['breadcrumbs-spa-profile'],
              t['breadcrumbs-my-bookings'],
              t['breadcrumbs-view-scheduling']
            ]}
            navigationMap={{
              'Home': 'LandingPage',
              'Spa Profile': 'SpaUserProfile',
              'SPA Bookings': 'SpaBooking',
            }}
            navigation={this.props.navigation}
            forceUpdate={() => {
              this.forceUpdate.bind(this)();
              // Renew service selection value in the Service Type dropdowm
              this.setInitScheduleServiceType.bind(this, '')()
            }}
            context={this}
          >
            <Box
              dir={dir}
              display='flex'
              mt={5}
              justifyContent='space-between'
            >
              <Box position='relative' width='70%'>
                <Button
                  data-test-id={"btnAcceptTerms"}
                  type='submit'
                  variant="contained"
                  color="primary"
                  className="agreeButton"
                  style={{
                    width: 'auto',
                    fontSize: '16px',
                    padding: '5px 15px',
                    height: 'auto',
                    position: 'absolute',
                    top: '24px',
                    backgroundColor: "#398378",
                    fontWeight: 700,
                    textTransform: "none",
                    fontFamily: "Ubuntu",
                    ...schedButtonPosition
                  }}
                  startIcon={
                    <ChevronLeftRoundedIcon
                      style={{
                        color: '#ffffff',
                        paddingRight: '8px',
                        ...elementFlip
                      }}
                    />}
                  onClick={this.gotoMyBooking}
                >
                  {t['profile-my-bookings-title']}
                </Button>
                <FullCalendar
                  {...isArabic && {
                    locale: arLocale
                  }}
                  plugins={[dayGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  headerToolbar={{
                    start: 'title,prev,next',
                    center: '',
                    end: 'myBookingsButton'
                  }}
                  titleFormat={
                    { month: 'long', year: '2-digit' }
                  }
                  firstDay={1}
                  events={this.state.filteredScheduledBookings.map((booking) => ({
                    ...booking,
                    title: booking.title
                  }))}
                  eventClick={(info) =>
                    this.setScheduleCancelModal(info.event._def.publicId)
                  }
                  datesSet={async (event) => this.setState({
                    scheduleDate:
                      dayjs(event.view.currentStart)
                        .format("YYYY-MM-DD"),
                  })
                  }
                />
              </Box>

              <Box width='27%'>
                <Select
                  data-select-id="service-type-select"
                  fullWidth
                  variant="outlined"
                  value={this.state.scheduleServiceType}
                  displayEmpty
                  IconComponent={() =>
                    <PlayArrowIcon
                      fontSize='small'
                      style={{
                        color: '#ffffff',
                        position: 'absolute',
                        ...selectArrow,
                        ...elementFlip
                      }}
                    />}
                  renderValue={
                    this.state.scheduleServiceType ?
                      undefined :
                      () =>
                        <div
                          style={{
                            color: '#FFFFFF',
                            fontWeight: 700,
                            fontFamily: "Ubuntu"
                          } as React.CSSProperties
                          }
                        >
                          {t['booking-schedule-select']}
                        </div>
                  }
                  onChange={(event) => this.setScheduleServiceType(event, t)}
                  style={{
                    height: '36px',
                    backgroundColor: '#398378',
                    color: '#ffffff',
                    fontWeight: 700,
                    borderRadius: "8px",
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    style: {
                      maxHeight: '50vh'
                    },
                  }}
                >
                  {scheduledCategoriesList.map((arrItem, index) => <MenuItem key={index} value={arrItem}>{arrItem}</MenuItem>)}
                </Select>
                <Box
                  mt={2}
                  p={2}
                  pb={6}
                  maxHeight='55vh'
                  overflow='auto'
                  borderRadius={16}
                  bgcolor='#FFFFFF'
                >
                  {this.state.scheduledBookings
                    .filter((booking) =>
                      this.state.scheduleServiceType === getTransContent(booking).category ||
                      this.state.scheduleServiceType === allServiceType
                    )
                    .map((card) =>
                      <Box
                        key={card.id}
                        p={1}
                        mb={1}
                        borderRadius='8px'
                        display='flex'
                        gridGap={8}
                        style={{ backgroundColor: '#FFFFFF' }}
                      >
                        <Box height={56} maxWidth={56} minWidth={56} mr={2}>
                          <img
                            src={card.photo || defaultProfile}
                            alt='Profile Photo'
                            style={{
                              objectFit: 'cover',
                              width: '100%',
                              height: '100%',
                              borderRadius: '8px'
                            }} />
                        </Box>
                        <Box overflow='hidden' display='flex' justifyContent='space-between' flexDirection='column'>
                          <Typography
                            style={{
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden'
                            }}
                          >{card.name}{' '}
                            <span style={{
                              color: card.color
                            }}>
                              {`(${card.title})`}
                            </span>
                          </Typography>
                          <Typography
                            style={{
                              color: '#64748B'
                            }}
                          >{card.time}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  {this.state.loading &&
                    <Box
                      minHeight='300px'
                      width='100%'
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                    >
                      <CircularProgress
                        data-test-id='spinner'
                        style={{ color: '#398378' }}
                      />
                    </Box>
                  }
                  {!(this.state.loading || this.state.scheduledBookings.length) &&
                    <Box>
                      <Typography
                        style={{
                          textAlign: 'center',
                          marginBottom: '48px'
                        }}>
                        {t['booking-no-bookings-img-title']}
                      </Typography>
                      <img
                        data-test-id='no-booking image'
                        src={bookingSchedule}
                        alt='Booking Schedule'
                        style={{ maxWidth: '100%' }}
                      />
                    </Box>}
                </Box>
              </Box>

            </Box>
          </BookingWrapper>
          <BookingModal
            openRescheduleModal={this.state.openRescheduleModal}
            setOpenRescheduleModal={this.setOpenRescheduleModal}
            navigation={this.props.navigation}
            id={`${this.state.selectedBookingId}`}
            modalContent={{
              title: t['booking-cancel-prompt-title'],
              buttonTitle: t['booking-cancel-prompt-btn'],
            }}
            modalData={{
              name: this.state.selectedSchedule.name,
              serviceName: this.state.selectedSchedule.title,
              duration: {
                id: 0,
                duration: this.state.selectedSchedule.duration,
                price: this.state.selectedSchedule.price,
                dates: [],
                timeslots: []
              },
              slot: this.state.selectedSchedule.time.split(", ")[1],
              date: dayjs(this.state.selectedSchedule.start).format("DD MMMM"),
              price: Number(this.state.selectedSchedule.price),
            }}
          />
          <PlanExpiryPrompt context={this} userAction={this.state.userAction} />
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
