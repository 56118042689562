import React, { useEffect, useRef, useState } from 'react';
import ConfirmationModal from './ConfirmationModal';
import { getIsExpired, getIsTrial, getTranslationConfig } from './helpers';
import { Message } from '../../framework/src/Message';
import MessageEnum, { getName } from '../../framework/src/Messages/MessageEnum';
import { planExpiry } from './consts';

export const PlanExpiryPrompt = ({ context, userAction }: { context: any, userAction?: boolean }) => {
    const { t } = getTranslationConfig();

    const [isTrial, setIsTrial] = useState(false);
    const [openExpiryPrompt, setOpenExpiryPrompt] = useState(false);

    const message = isTrial ? t['subscription-trial-text'] : t['subscription-expired-text'];

    useEffect(() => {

        // We need to wait when plan values are saved to local storage while user logs in
        setTimeout(() => {
            // Don't show expiry prompt for trial period during the first page load
            if (userAction === undefined && getIsTrial()) return;

            const currentExpiryPlans = JSON.parse(localStorage.getItem(planExpiry) as string);
            const isTrial = currentExpiryPlans?.isTrial;
            const isExpired = currentExpiryPlans?.isExpired;

            isTrial && setIsTrial(true);

            (isTrial || isExpired) && setOpenExpiryPrompt(true);
        }, 0);
    }, [userAction])

    const goToSubscriptionPlans = () => {
        const msg: Message = new Message(
            getName(MessageEnum.NavigationSubscriptionPlans)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), context?.props);
        context?.send(msg);
    };

    return <ConfirmationModal
        openConfirmationModal={openExpiryPrompt}
        setOpenConfirmationModal={setOpenExpiryPrompt}
        modalConfig={{
            title: '',
            message,
            confirmText: t['subscription-renew-btn'],
            cancelText: t['profile-settings-logout-cancel-text'],
            onConfirm: goToSubscriptionPlans,
        }}
    />
}