import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Link,
  Typography,
} from "@material-ui/core";
import { img1475048885HTpe556x7cBoLvN2ZuRRme, imgFacebook, imgInst, imgTwitter, imgYouTube } from './assets';
import { getStorageData, setStorageData } from '../../framework/src/Utilities';
import { Message } from '../../framework/src/Message';
import MessageEnum, { getName } from '../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../framework/src/RunEngine';
import { useRunEngine } from '../../blocks/utilities/src/hooks/useRunEngine';
import { useBlockHelpers } from '../../blocks/utilities/src/hooks/useBlockHelpers';
export const configJSON = require("../../blocks/landingpage/src/config");

interface StoredDataType {
  contactData: {
    data: [
      {
        id: string;
        type: string;
        attributes: {
          name: string;
          email: string;
          phone_number: string;
          description: string;
          created_at: string;
          user: string;
        };
      }
    ];
  };
  currentPlanStatus: string;
}

interface AppFooterProps {
  navigation: any;
}

interface SocialMediaLink {
  id: number;
  facebook: string;
  instagram: string;
  twitter: string;
  youtube: string;
  other_links: string;
}

const subscribedMessages = [
  MessageEnum.RestAPIResponceMessage,
  MessageEnum.SessionResponseMessage,
];


export function AppFooter(props: AppFooterProps) {
  const { navigation } = props;
  const goToTermsAndCondition = () => navigation.navigate('TermsConditions');
  const isCustomer = localStorage.getItem('userRole') === 'Customer';
  const getCountryCallId = useRef("");

  const [storedData, setStoredData] = useState<StoredDataType | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [countryData, setCountryData] = useState<string[]>([]);
  const [socialMediaLinks, setSocialMediaLinks] = useState<SocialMediaLink[] | null>(null);

  const {
    sendBlockMessage,
    sendNetworkRequest,
    setReceiveCallback,
    subscribe,
    debugLog,
    unsubscribeFromMessage,
  } = useRunEngine();

  const { extractNetworkResponse } = useBlockHelpers();


  const receive = (from: string, message: Message) => {
    debugLog("API Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } =
        extractNetworkResponse(message);

      if (responseJson) {
        if (apiRequestCallId === getCountryCallId.current) {
          if (responseJson) {
            setSocialMediaLinks(responseJson);
            getCountryCallId.current = "";
          }
        }
        getCountryCallId.current = "";
      }
    }
  };

  const getOrder = () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
    };

    sendNetworkRequest(
      getCountryCallId,
      configJSON.landingPageApiMethodType,
      configJSON.socialMediaIconsGet,
      headers
    );
  };

  useEffect(() => {
    setReceiveCallback(receive);
    subscribedMessages.forEach((message) => subscribe(message));

    const fetchData = async () => {
      const data = await getStorageData('contactInfo');
      const parsedData = JSON.parse(data);
      setStoredData(parsedData);

      const countryData = await getStorageData('countryDataInfo');
      const parsedCountryData: { countryData: string[] } = JSON.parse(countryData);
      const uniqueCountries = [...new Set(parsedCountryData?.countryData.map((country) => country.toLowerCase()))]
        .map((country) => country.charAt(0).toUpperCase() + country.slice(1));

      setCountryData(uniqueCountries);

      const userRoleData = await getStorageData('userRole');
      setUserRole(userRoleData);
    };
    fetchData();
    getOrder()

    return () => {
      subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
    };
  }, []);

  const handleSubscriptionNavigation = () => {

    if (userRole === 'Customer') {
      navigation.navigate('SubscriptionbillingPlans');
    } else {
      navigation.navigate(storedData?.currentPlanStatus === 'Active' ? 'SubscriptionbillingPlans' : 'Subscriptionbilling');
    }
  };

  const handleCountryClick = (country: string) => {
    setStorageData('selectedCountry', country);
    window.location.href = (`/SpasByCountry/${country}`)
  };

  return (
    <>
      <Box
        style={{
          backgroundColor: '#398378ff',
          padding: '40px 141px'
        }}
        data-view-name={{ viewName: 'group_footer' }}
      >
        <Box>
          <img
            style={{ width: 147, height: 60 }}
            src={img1475048885HTpe556x7cBoLvN2ZuRRme}
          />
        </Box>
        <hr color='#94A3B8' style={{ margin: '32px 0' }} />
        {/* footer bar */}
        <Box
          display='flex'
          justifyContent='flex-start'
        >
          {isCustomer &&
            <>
              <Box minWidth='25%'>
                <Typography
                  style={{
                    fontFamily: 'Ubuntu',
                    fontWeight: 700,
                    fontSize: 16,
                    letterSpacing: 0,
                    color: '#ffffffff',
                    paddingBottom: '4px'
                  }}
                >{`Explore`}</Typography>
                <Link
                  style={{
                    padding: '4px 0',
                    display: 'block',
                    fontFamily: 'Ubuntu',
                    fontWeight: 400,
                    fontSize: 16,
                    letterSpacing: 0,
                    color: '#ffffffff',
                    cursor: 'pointer'
                  }}
                  onClick={() => navigation.navigate('LandingPage')}
                >{`Home`}</Link>
                <Link
                  style={{
                    padding: '4px 0',
                    display: 'block',
                    fontFamily: 'Ubuntu',
                    fontWeight: 400,
                    fontSize: 16,
                    letterSpacing: 0,
                    color: '#ffffffff',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleSubscriptionNavigation()}
                >{`Subscriptions`}</Link>
              </Box>
              <Box minWidth='25%'>
                <Typography
                  style={{
                    fontFamily: 'Ubuntu',
                    fontWeight: 700,
                    fontSize: 16,
                    letterSpacing: 0,
                    color: '#ffffffff',
                    paddingBottom: '4px'
                  }}
                >{`Location`}</Typography>
                {countryData?.slice(0, 5).map((country, index) => (
                  <Link
                    key={index}
                    style={{
                      padding: '4px 0',
                      display: 'block',
                      fontFamily: 'Ubuntu',
                      fontWeight: 400,
                      fontSize: 16,
                      letterSpacing: 0,
                      color: '#ffffffff',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      handleCountryClick(country);
                    }}
                  >
                    {country}
                  </Link>
                ))}
              </Box>
            </>}
          {storedData && storedData.contactData && storedData.contactData.data && storedData.contactData.data.length > 0 && (
            <Box minWidth='25%'>
              <Typography
                style={{
                  fontFamily: 'Ubuntu',
                  fontWeight: 700,
                  fontSize: 16,
                  letterSpacing: 0,
                  color: '#ffffffff',
                  paddingBottom: '4px'
                }}
              >
                {`Contact Us`}
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Ubuntu',
                  fontSize: 16,
                  letterSpacing: 0,
                  color: '#ffffffff',
                  paddingBottom: '4px'
                }}
              >
                {`Address: ${storedData?.contactData.data[0].attributes.name}`}
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Ubuntu',
                  fontSize: 16,
                  letterSpacing: 0,
                  color: '#ffffffff',
                  paddingBottom: '4px'
                }}
              >
                {`Mail: ${storedData?.contactData.data[0].attributes.email}`}
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Ubuntu',
                  fontSize: 16,
                  letterSpacing: 0,
                  color: '#ffffffff',
                  paddingBottom: '4px'
                }}
              >
                {`Phone: ${storedData?.contactData.data[0].attributes.phone_number}`}
              </Typography>
              <Link
                style={{
                  padding: '4px 0',
                  display: 'block',
                  fontFamily: 'Ubuntu',
                  fontWeight: 400,
                  fontSize: 16,
                  letterSpacing: 0,
                  color: '#ffffffff',
                  cursor: 'pointer'
                }}
                onClick={() => navigation.navigate('ContactUsPage')}
              >
                {`Get Support`}
              </Link>
            </Box>
          )}

          <Box minWidth='25%' marginBottom='74px'>
            <a
              style={{
                fontFamily: 'Ubuntu',
                fontWeight: 700,
                fontSize: 16,
                letterSpacing: 0,
                color: '#ffffffff',
                marginBottom: '20px',
                cursor: 'pointer',
                display: 'block'
              }}
              onClick={goToTermsAndCondition}
            >{`Terms & Condition`}</a>
            <Box display='flex' gridGap='20px'>
              <Box display='flex' gridGap='20px'>
                {socialMediaLinks?.[0] && (
                  <>
                    <Link href={socialMediaLinks[0].facebook || '#'} target="_blank" style={{ cursor: 'pointer' }}>
                      <img src={imgFacebook} alt="img-facebook" />
                    </Link>
                    <Link href={socialMediaLinks[0].twitter || '#'} target="_blank" style={{ cursor: 'pointer' }}>
                      <img src={imgTwitter} alt="img-twitter" />
                    </Link>
                    <Link href={socialMediaLinks[0].instagram || '#'} target="_blank" style={{ cursor: 'pointer' }}>
                      <img src={imgInst} alt="img-instagram" />
                    </Link>
                    <Link href={socialMediaLinks[0].youtube || '#'} target="_blank" style={{ cursor: 'pointer' }}>
                      <img src={imgYouTube} alt="img-youtube" />
                    </Link>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box height={62} style={{ backgroundColor: '#000000' }} display='flex' alignItems='center' justifyContent='center'>
        <Typography
          style={{
            fontFamily: 'Ubuntu',
            fontWeight: 400,
            fontSize: 14,
            letterSpacing: 0,
            color: '#ffffffff',
            height: '14px'
          }}
        >{`© 2024 All rights reserved`}</Typography>
      </Box>
    </>
  )
}
