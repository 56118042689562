import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { GalleryBlock } from '../../../components/src/GalleryBlock.web';
import { getTranslationConfig } from "../../../components/src/helpers";
import ConfirmationModal from '../../../components/src/ConfirmationModal';

const ButtonComponent = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center !important",
  justifyContent: 'space-between',
  padding: "30px 0px",
  '& .regButton': {
    display: 'flex',
    width: '150px',
    height: '65px',
    borderRadius: '8px',
    fontSize: '12px',
    background: 'var(--Primary, #398378)',
    fontFamily: 'Ubuntu',
    textTransform: 'none',
    textAlign: 'center',
    padding: 0
  }
});

const customStyles = {
  plainText: {
    fontWeight: 400,
    width: 'fit-content'
  }
};
// Customizable Area End

import Ordermanagement1Controller, {
  Props,
  configJSON,
} from "./Ordermanagement1Controller";
import { TypographyView, styles } from './styledFont';
import { notFound, notFoundAr } from './assets';
import { BookingWrapper } from './components/BookingWrapper';
import CancelModal from './components/CancelModal';
import Loader from "../../../components/src/Loader.web";
import { PlanExpiryPrompt } from '../../../components/src/PlanExpiryPrompt';

export default class Ordermanagement1 extends Ordermanagement1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subscriptionRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  subscriptionRef: any;

  navigationMap = {
    'Home': 'LandingPage',
    'Spa Profile': 'SpaUserProfile',
  }
  // Customizable Area End

  render() {
    const { t, dir, isArabic } = getTranslationConfig();
    const isRestrictedByPlan = this.state.currentSubscription === 1;

    const buttons = [
      {
        title: t['bookings-cancel'],
        handler: (id: number) => this.setOpenCancelModal.bind(this)(id),
      },
      {
        title: t['bookings-view-details'],
        handler: (id: number) => this.gotoBookingDetails.bind(this)(id),
      }
    ];

    this.state.viewMode !== 'booked' && buttons.shift();

    if (!this.subscriptionRef.current && this.state.isSpa) {
      this.getUserCurrentSubscription();

      this.subscriptionRef.current = true;
    }

    return (
      // Customizable Area Start
      <>
        <BookingWrapper
          navigation={this.props.navigation}
          navigationMap={this.navigationMap}
          breadcrumbs={[t['breadcrumbs-home'], t['breadcrumbs-spa-profile'], t['breadcrumbs-my-bookings']]}
          heading={t['profile-my-bookings-title']}
          forceUpdate={this.forceUpdate.bind(this)}
          context={this}
        >
          <ButtonComponent style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}>
            {/* Statuses buttons */}
            <Box display='flex' gridGap={16}>
              <Button
                data-test-id='completed-btn'
                variant="contained"
                color="primary"
                onClick={() => this.setState({ viewMode: 'completed' })}
                className="regButton"
                style={this.getButtonBorder('completed')}
              >
                <Box width='100%' color='#047854' textAlign='left' style={{ width: 'fit-content' }} >
                  <Typography style={customStyles.plainText}>
                    {t['bookings-completed']}
                  </Typography>
                  <Typography style={customStyles.plainText}>
                    <span>{this.state.bookingsCount.completed}</span> <span>{t['bookings-appointments']}</span>
                  </Typography>
                </Box>
              </Button>
              <Button
                data-test-id='booked-btn'
                variant="contained"
                color="primary"
                onClick={() => this.setState({ viewMode: 'booked' })}
                className="regButton"
                style={this.getButtonBorder('booked')}

              >
                <Box width='100%' color='#D97706' textAlign='left' style={{ width: 'fit-content' }}>
                  <Typography style={customStyles.plainText}>
                    {t['bookings-upcoming']}
                  </Typography>
                  <Typography style={customStyles.plainText}>
                    <span>{this.state.bookingsCount.booked}</span> <span>{t['bookings-appointments']}</span>
                  </Typography>
                </Box>
              </Button>
              <Button
                data-test-id='cancel-btn'
                variant="contained"
                color="primary"
                onClick={() => this.setState({ viewMode: 'cancelled' })}
                className="regButton"
                style={this.getButtonBorder('cancelled')}
              >
                <Box width='100%' color='#DC2626' textAlign='left' style={{ width: 'fit-content' }}>
                  <Typography style={customStyles.plainText}>
                    {t['bookings-cancelled']}
                  </Typography>
                  <Typography style={customStyles.plainText}>
                    <span>{this.state.bookingsCount.cancelled}</span> <span>{t['bookings-appointments']}</span>
                  </Typography>
                </Box>
              </Button>
            </Box>
            {/* UI functional buttons */}
            <Box display='flex' gridGap={10}>
              <Button
                data-test-id='schedule-btn'
                variant="contained"
                color="primary"
                onClick={() => this.gotoCalendar()}
                className="regButton"
                style={{
                  fontSize: '16px'
                }}
              >
                {t['bookings-view-scheduling']}
              </Button>
              <Button
                data-test-id='booking-form-btn'
                variant="contained"
                color="primary"
                onClick={() => {
                  isRestrictedByPlan ? this.setShowRestrictedPrompt(true,
                    {
                      title: '',
                      message: 'Your current subscription plan (Silver) does not grant access to this feature. To unlock this feature and enhance your profile, please upgrade to the Gold .',
                      confirmText: 'Upgrade Now ',
                      cancelText: t['profile-settings-logout-cancel-text'],
                      onConfirm: this.gotoSubscriptionPlans,
                    }
                  ) :
                    this.gotoBookingForm();
                }}
                className="regButton"
                style={{
                  background: 'var(--Primary, #D9EEEB)',
                  color: '#047854'
                }}
              >
                <AddCircleOutlineIcon
                  className="AddIconStyle" style={{
                    color: '#398378',
                    maxHeight: '22px'
                  }} />
                <Typography style={{
                  margin: '0 4px',
                  fontWeight: 700
                }}>
                  {t['bookings-new-booking']}
                </Typography>
              </Button>
            </Box>
          </ButtonComponent>
          {/* Not found picture */}
          {this.state.loading ? (
            <Loader loading />
          ) : (
            <Box>
              {this.state.customerProfilesPage.length ?
                <Box dir='ltr'>
                  <GalleryBlock
                    products={this.state.customerProfilesPage}
                    buttons={buttons}
                    narrowImages
                    formatDateToTime={(time: string, isDate?: boolean) => this.formatDateToTime(time, isArabic, isDate)}
                  />
                  {/* Pagination block */}
                  {!!this.state.lastPage && (
                    <Box
                      display='flex'
                      alignItems='center'
                      gridGap={12}
                      mt={3}
                      margin='auto'
                      width='fit-content'
                    >
                      <Box
                        data-test-id='pagination-back-btn'
                        onClick={this.goBack.bind(this)}
                        {...styles.arrowWrapper}
                        border={`1px solid ${this.getGoBackColor()}`}
                      >
                        <ArrowBackIosIcon
                          style={{
                            width: '15px',
                            marginLeft: '6px',
                            color: this.getGoBackColor()
                          }} />
                      </Box>
                      <Box
                        dir={dir}
                        fontSize='18px'
                        color='#64748B'
                        fontFamily='Ubuntu'
                      >
                        {this.state.currentPage} {t['pagination-of']} {this.state.lastPage}
                      </Box>
                      <Box
                        data-test-id='pagination-next-btn'
                        onClick={this.goForward.bind(this)}
                        {...styles.arrowWrapper}
                        border={`1px solid ${this.getGoForwardColor()}`}
                      >
                        <ArrowForwardIosIcon
                          style={{
                            width: '15px',
                            color: this.getGoForwardColor()
                          }} />
                      </Box>
                    </Box>
                  )}
                </Box>
                :
                <Box
                  mt={4}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img
                    src={isArabic ? notFoundAr : notFound}
                    style={{
                      width: "38rem",
                      height: "17rem"
                    }}
                    alt="not-found"
                  />
                </Box>
              }
            </Box>
          )}
        </BookingWrapper>
        <CancelModal
          openCancelModal={this.state.openCancelModal}
          setOpenCancelModal={this.setOpenCancelModal}
          onCancelBooking={this.cancelBooking}
          bookedID={this.state.selectedBookingId}
          navigation={this.props.navigation}
          userName={this.selectedBookingUserName()}
          id=''
        />
        <ConfirmationModal
          openConfirmationModal={this.state.showRestrictedPrompt}
          setOpenConfirmationModal={this.setShowRestrictedPrompt}
          modalConfig={this.state.modalConfig}
        />
        <PlanExpiryPrompt context={this} userAction={this.state.userAction} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
