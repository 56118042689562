import React from 'react';

// Customizable Area Start
import { Box, Typography, Tabs, Tab, CircularProgress, LinearProgress, Select, MenuItem, styled } from '@material-ui/core';
import { Chart } from 'react-google-charts';
import './charts.css'
// Customizable Area End

import VisualAnalyticsController, {
  Props,
} from './VisualAnalyticsController';
import { styles, TypographyView } from '../../../components/src/styles';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { BreadCrumbs } from '../../../components/src/BreadCrumbs.web';
import { AppFooter } from '../../../components/src/AppFooter.web';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import { CustomTabPanel } from './components/CustomTabPanel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getRtlStyleValue, getTransContent, getTranslationConfig } from '../../../components/src/helpers';
import { arabicMonths } from '../../../components/src/consts';

const customStyle = {
  arrows: {
    color: '#000000',
    height: '15px',
    position: 'relative'
  },
  statRowFirstHead: {
    fontSize: '16px',
    fontWeight: 700,
    width: '70%',
  },
  statRowSecondHead: {
    fontSize: '16px',
    width: '30%',
    textAlign: 'center',
    fontWeight: 700,
  },
  statRowName: {
    fontSize: '12px',
    width: '70%',
    textAlign: 'left',
  },
  statRowVal: {
    fontSize: '12px',
    width: '30%',
    textAlign: 'center',
  },
  legend: {
    fontSize: '16px',
    marginLeft: '8px',
    color: '#000000'
  },
  blockTitle: {
    color: '#398378',
    fontSize: '18px',
    fontWeight: 700,
    marginBottom: '8px'
  },
  chartPlaceholder: {
    position: 'absolute',
    width: 450,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

const CustomSelect = styled(Select)({
  '& .MuiInput-input': {
    color: '#334155',
    width: '100px',
    fontWeight: 400
  },
})

export default class VisualFullAnalytics extends VisualAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  navigationMap = {
    'Home': 'LandingPage',
    'Spa Profile': 'SpaUserProfile',
  }

  getCustomTab = (index: number, value: number | undefined, text: string) =>
    <Tab
      {...this.getA11yProps(index)}
      label={
        <Box
          display='flex'
          justifyContent='space-between'
          width='100%'
        >
          <Box>
            <Typography
              style={{
                fontSize: '30px',
                fontWeight: 700,
                color: '#000000',
                textAlign: 'left',
                minHeight: '45px',
                width: 'fit-content'
              }}
            >
              {value}
            </Typography>
            <Typography
              style={{
                color: '#0F172A',
                textTransform: 'none'
              }}>
              {text}
            </Typography>
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
          >
            <KeyboardArrowDown
              style={{
                top: '3px',
                ...customStyle.arrows
              } as React.CSSProperties} />
            <KeyboardArrowUp
              style={{
                top: '-3px',
                ...customStyle.arrows
              } as React.CSSProperties} />
          </Box>
        </Box>}
    />

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, isArabic, dir, locale } = getTranslationConfig();

    const geoProps = {
      local: t['performance-dashboard-customers-number-locally'],
      global: t['performance-dashboard-customers-number-globally']
    };

    const statisticsTypes = [
      t['performance-dashboard-tab-sales'],
      t['performance-dashboard-tab-client']
    ];

    const activeTab = this.state.activeTabIndex;
    const showFirstStatBlock = this.state.activeAnalysisTypeIndex === 0;
    const showSecondStatBlock = this.state.activeAnalysisTypeIndex === 1;

    const yearOptions = this.getYearOptions(t);

    const {
      total_bookings,
      monthly_sales_data,
      online_offline_bookings_per_year,
      get_bookings_by_geolocation,
      booking_by_categories: services,
      bookings_by_gender,
      total_amounts_by_booking_type
    } = this.state.fullReport || {};

    const servicesCount = services?.length;

    const maxBookingValue = Number(services?.reduce(
      (
        acc: number,
        next: { booked_slots_count: number }
      ) =>
        Math.max(acc, next.booked_slots_count), 0
    ));

    const { labels, data: yAxisData } = monthly_sales_data?.data || {};

    const {
      online_bookings: online,
      offline_bookings: offline
    } = online_offline_bookings_per_year || {};

    const {
      total_bookings_locally: totalBookingsInt,
      total_bookings_internationally: totalBookingsLoc
    } = get_bookings_by_geolocation || {};

    const {
      female,
      male
    } = bookings_by_gender || {};

    const {
      offline_total_amount: offAmount,
      online_total_amount: onAmount,
      sum_total_amount: sumAmount
    } = total_amounts_by_booking_type || {};

    const genderStat = [
      {
        name: t['performance-dashboard-female'],
        value: female
      },
      {
        name: t['performance-dashboard-male'],
        value: male
      }
    ];

    const bookingSalesData: (
      string | number | undefined |
      Record<string, string | Record<string, boolean>> |
      React.ReactNode
    )[][] = [[
      '',
      'No. of Booking',
      { type: 'string', role: 'style' },
      { type: 'string', role: 'tooltip', p: { 'html': true } }
    ]];

    labels?.forEach((label: string, index: number) => {
      const curYear = new Date().getFullYear();
      const stateYear = Number(this.state.year);
      const yearToShow = isNaN(stateYear) ? curYear : stateYear;

      const shortMonth = label.slice(0, 3);

      const transLabel = isArabic ? arabicMonths[shortMonth.toLowerCase()] : shortMonth;

      const formattedDate = new Date(`${label} ${yearToShow}`)
        .toLocaleString(locale, {
          month: 'long',
          year: 'numeric',
        })

      const axisValue = (yAxisData as number[])[index];
      const chartItem = [
        transLabel,
        axisValue,
        'point { fill-color: #F59E0B; }',
        `<div>
          <div style='padding: 8px; background-color: #F1F5F9; font-size: 12px'>
          ${formattedDate}
          </div>
          <div style='padding: 8px; background-color: #D9EEEB; font-size: 12px'>
            ${t['performance-dashboard-booking-number']} <span style='font-weight: 700; margin-left: 12px'>${axisValue}</span>
          </div>
        </div>`
      ];
      bookingSalesData.push(chartItem);
    })

    const bokingSalesOptions = {
      curveType: 'function',
      legend: { position: 'none' },
      colors: ['#398378'],
      vAxis: { format: 'short' },
      tooltip: {
        isHtml: true,
        trigger: 'selection'
      }
    };

    const servicesStatData = [
      ['Service Type', 'Type Percentage'],
      [t['performance-dashboard-online-booking'], online],
      [t['performance-dashboard-offline-booking'], offline]
    ];

    const locationStatData = [
      ['No. of customers', 'No. Percentage'],
      [geoProps.global, totalBookingsInt],
      [geoProps.local, totalBookingsLoc]
    ];

    const noOfCustomers = Number(totalBookingsInt) + Number(totalBookingsLoc);

    const donutServicesOptions = {
      pieHole: 0.75,
      legend: {
        position: 'labeled',
        color: '#334155',
      },
      pieSliceText: 'none',
      backgroundColor: '#E8F6F4',
      chartArea: {
        ...getRtlStyleValue(isArabic).chartAreaPosition,
        height: 300,
        width: 450
      },
      colors: ['#398378', '#F59E0B']
    };

    const donutLocationOptions = {
      ...donutServicesOptions,
      backgroundColor: '#FFFFFF',
      legend: {
        position: 'none',
      }
    }

    return (
      <TypographyView>
        {this.state.isLoading &&
          <CircularProgress
            size={64}
            style={{
              ...styles.absoluteCentered as React.CSSProperties,
              color: '#398378'
            }}
          />}
        <Box style={styles.SafeAreaView} className='landing-venue-block'>
          <AppHeader context={this} forceUpdate={this.forceUpdate.bind(this)} />
          <Box
            className='search-location-block'
            py={6}
            px='140px'
            dir={dir}
            style={{ overflowX: 'hidden' }}
          >
            <BreadCrumbs
              items={[
                t['breadcrumbs-home'],
                t['breadcrumbs-spa-profile'],
                t['breadcrumbs-performance-dashboard']
              ]}
              targetColor='#398378'
              navigationMap={this.navigationMap}
              navigate={this.props.navigation.navigate}
            />
            <Typography
              style={{
                color: '#0F172A',
                fontFamily: 'Ubuntu',
                fontSize: '48px',
                fontWeight: 700,
                padding: '30px 0'
              }}
            >{t['performance-dashboard-title']}</Typography>
            <Typography>{t['performance-dashboard-description']}</Typography>
            <Box mt={4} display='flex' gridGap={16}>
              {statisticsTypes.map((statType, index) =>
                <Box
                  data-test-id='statistics-tabs'
                  border='1px solid #7EC4B9'
                  borderRadius={16}
                  p={2}
                  minWidth='275px'
                  minHeight='124px'
                  {...styles.centered}
                  key={statType}
                  style={{ cursor: 'pointer', ...this.setActiveAnalysisTypeStyle(index) }}
                  onClick={() => this.setActiveAnalysisTypeIndex(index)}
                >
                  <Typography
                    style={{
                      fontSize: '18px',
                      fontWeight: 700
                    }}
                  >
                    {statType}
                  </Typography>
                </Box>)}
            </Box>
            {showFirstStatBlock &&
              <>
                <Box my={4} minHeight={32}>
                  {!activeTab &&
                    <CustomSelect
                      data-test-id='year-select'
                      defaultValue='2024'
                      disableUnderline
                      onChange={this.setYear.bind(this)}
                      IconComponent={ExpandMoreIcon}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {yearOptions.map((year) => <MenuItem key={year.value} value={year.value}>{year.name}</MenuItem>)}
                    </CustomSelect>}
                </Box>
                {/* Tabs */}
                <Box>
                  <Box>
                    <Tabs
                      value={activeTab}
                      variant='fullWidth'
                      onChange={
                        (_, newValue: any) =>
                          this.setActiveTabIndex(newValue)}
                      aria-label='basic tabs example'
                    >
                      {this.getCustomTab(0, total_bookings, t['performance-dashboard-total-bookings-number'])}
                      {this.getCustomTab(1, servicesCount, t['performance-dashboard-total-services-booked'])}
                    </Tabs>
                  </Box>
                </Box>
                <Box
                  mt={3}
                  width='100%'
                  position='relative'
                  minHeight='375px'
                >
                  {/* Linear chart */}
                  <CustomTabPanel value={activeTab} index={0}>
                    {this.state.fullReport ?
                      <Chart
                        chartType='AreaChart'
                        width='100%'
                        height='375px'
                        data={bookingSalesData}
                        options={bokingSalesOptions}
                      /> :
                      <CircularProgress
                        style={{
                          color: '#398378',
                          ...styles.absoluteCentered
                        } as React.CSSProperties}
                      />}
                  </CustomTabPanel>
                  {/* Donut chart */}
                  <CustomTabPanel value={activeTab} index={1}>
                    <Box
                      display='flex'
                      flexWrap='nowrap'
                      width='100%'
                    >
                      <Box
                        width='50%'
                        position='relative'
                      >
                        <Box {...customStyle.chartPlaceholder} >
                          <Typography
                            style={{
                              fontSize: '18px',
                              fontWeight: 700,
                              zIndex: 1,
                            } as React.CSSProperties}>
                            {this.currency} {sumAmount}
                          </Typography>
                        </Box>
                        <Chart
                          chartType='PieChart'
                          data={servicesStatData}
                          options={donutServicesOptions}
                          height='310px'
                        />
                        {/* Static legend */}
                        <Box
                          position='absolute'
                          {...getRtlStyleValue(isArabic).chartAreaLegendPosition}
                        >
                          <Box
                            display='flex'
                            alignItems='center'
                            gridGap={4}
                          >
                            <Box
                              bgcolor='#398378'
                              height={12}
                              width={12}
                              borderRadius={50}
                            />
                            <span style={customStyle.legend}>
                              {t['performance-dashboard-online-booking']} {this.currency} {onAmount}
                            </span>
                          </Box>
                          <Box
                            display='flex'
                            alignItems='center'
                            gridGap={4}
                          >
                            <Box
                              bgcolor='#F59E0B'
                              height={12}
                              width={12}
                              borderRadius={50}
                            />
                            <span style={customStyle.legend}>
                              {t['performance-dashboard-offline-booking']} {this.currency} {offAmount}
                            </span>
                          </Box>
                        </Box>
                      </Box>
                      <Box width='50%' display='flex' justifyContent='center' >
                        <Box width='65%'>
                          <Typography style={{
                            fontSize: '20px',
                            fontWeight: 700,
                            marginBottom: 16
                          }}>
                            {t['performance-dashboard-services-title']}
                          </Typography>
                          <Box display='flex' flexWrap='nowrap' py={1}>
                            <Box
                              style={{
                                fontWeight: 700,
                                ...getRtlStyleValue(isArabic).adHocAlign as React.CSSProperties,
                                ...customStyle.statRowFirstHead as React.CSSProperties
                              }}>
                              {t['performance-dashboard-category']}
                            </Box>
                            <Box
                              style={{
                                fontWeight: 700,
                                ...getRtlStyleValue(isArabic).adHocAlign as React.CSSProperties,
                                ...customStyle.statRowSecondHead as React.CSSProperties
                              }}>
                              {t['performance-dashboard-booking']}
                            </Box>
                          </Box>
                          {services?.map((booking) =>
                            <Box
                              display='flex'
                              flexWrap='nowrap'
                              py={1}
                              key={booking.id}
                            >
                              <Box style={customStyle.statRowName as React.CSSProperties}>
                                <Box>{getTransContent(booking).name}</Box>
                                <LinearProgress
                                  variant='determinate'
                                  value={booking.booked_slots_count / maxBookingValue * 100}
                                />
                              </Box>
                              <Box style={customStyle.statRowVal as React.CSSProperties}>{booking.booked_slots_count}</Box>
                            </Box>)}
                        </Box>
                      </Box>
                    </Box>
                  </CustomTabPanel>
                </Box>
              </>}
            {showSecondStatBlock &&
              <>
                <Typography
                  style={{
                    margin: '32px 0',
                    color: '#000000',
                    fontSize: '30px',
                    fontWeight: 700
                  }}>
                  {t['performance-dashboard-location-title']}
                </Typography>
                <Box
                  mt={3}
                  width='100%'
                  position='relative'
                  minHeight='375px'
                >
                  {/* Donut chart */}
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    flexWrap='nowrap'
                    width='100%'
                    gridGap={16}
                  >
                    <Box
                      width='55%'
                      position='relative'
                    >
                      <Typography style={customStyle.blockTitle}>
                        {t['performance-dashboard-location-sub-title-chart']}
                      </Typography>
                      <Box
                        {...customStyle.chartPlaceholder}
                        // Whole height - static legend
                        height='calc(100% - 72px)'
                      >
                        <Typography
                          style={{
                            fontSize: '18px',
                            fontWeight: 700,
                            zIndex: 1,
                            color: '#000000'
                          } as React.CSSProperties}>
                          {t['performance-dashboard-customers-number']}
                          <span
                            style={{
                              marginTop: '8px',
                              display: 'block',
                              fontWeight: 700,
                              textAlign: 'center'
                            }}
                          >
                            {noOfCustomers}
                          </span>
                        </Typography>
                      </Box>
                      <Chart
                        chartType='PieChart'
                        data={locationStatData}
                        options={donutLocationOptions}
                        height='350px'
                      />

                      {/* Static legend */}
                      <Box
                        width='100%'
                        bgcolor='#FFFFFF'
                        display='flex'
                        justifyContent='flex-end'
                        pb={2}
                        px={5}
                      >
                        <Box >
                          <Box display='flex' alignItems='center' >
                            <Box
                              bgcolor='#398378'
                              height={12}
                              minWidth={12}
                              borderRadius={50}
                            />
                            <span style={customStyle.legend}>
                              {geoProps.local}
                            </span>
                          </Box>
                          <Box display='flex' alignItems='center' >
                            <Box
                              bgcolor='#F59E0B'
                              height={12}
                              minWidth={12}
                              borderRadius={50}
                            />
                            <span style={customStyle.legend}>
                              {geoProps.global}
                            </span>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box width='45%'>
                      <Typography style={customStyle.blockTitle}>
                        {t['performance-dashboard-location-sub-title-table']}
                      </Typography>
                      <Box
                        height='calc(100% - 35px)'
                        bgcolor='#FFFFFF'
                        display='flex'
                        justifyContent='center'
                      >
                        <Box width='70%' pt={4}>
                          <Box display='flex' flexWrap='nowrap' py={1}>
                            <Box style={{ fontWeight: 700, ...customStyle.statRowFirstHead as React.CSSProperties }}>{t['performance-dashboard-gender']}</Box>
                            <Box style={{ fontWeight: 700, ...customStyle.statRowSecondHead as React.CSSProperties }}>{t['performance-dashboard-count']}</Box>
                          </Box>
                          {genderStat.map((booking) =>
                            <Box
                              display='flex'
                              flexWrap='nowrap'
                              py={1}
                              key={booking.name}
                            >
                              <Box style={customStyle.statRowName as React.CSSProperties}>
                                <Box>{booking.name}</Box>
                                <LinearProgress
                                  variant='determinate'
                                  value={Number(booking.value) / maxBookingValue * 100}
                                />
                              </Box>
                              <Box style={customStyle.statRowVal as React.CSSProperties}>{booking.value}</Box>
                            </Box>)}
                        </Box>
                      </Box>
                    </Box>

                  </Box>
                </Box>
              </>}
          </Box>
          {/* footer */}
          <AppFooter navigation={this.props.navigation} />
        </Box>
      </TypographyView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
